export default function setLocalData(key, value) {
  var responseStatus = false;
  let initialState = {};

  switch (key) {
    case 'user_info':
      initialState = {
        isLogin: true,
        isRegistered: true,
        userData: value,
      };
      localStorage.setItem(key, btoa(JSON.stringify(initialState)));
      localStorage.setItem('user_info_backup', btoa(JSON.stringify(initialState)));
      responseStatus = true;

      break;
   
    case 'admin_info':
      initialState = {
        isLogin: true,
        userData: value,
      };
      console.log(initialState, 'admin_info');
      localStorage.setItem(key, btoa(JSON.stringify(initialState)));
      localStorage.setItem('admin_info_backup', btoa(JSON.stringify(initialState)));
      responseStatus = true;

      break;
    default:
      responseStatus = false;

      break;
  }

  return responseStatus;
}
