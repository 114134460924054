import React, { useState, useEffect } from "react";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import Swal from "sweetalert2";

const MyOrders = () => {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [userid, setUserid] = useState();
  const [refundedData, setRefundedData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const totalPages = Math.ceil(totalProducts / limit);

  useEffect(() => {
    if (getLocalData("user_info", "isLogin")) {
      let userData = getLocalData("user_info", "all").userData.login_user_detail
        .data.user;
      setUserid(userData.id);
    }
  }, []);

  useEffect(() => {
    if (userid) {
      const fetchOrders = async () => {
        try {
          const orderResponse = await combinedServices.getOrdersforuser(
            userid,
            page,
            limit,
            searchQuery,
            paymentStatus,
            orderStatus
          );
          if (orderResponse?.data?.data) {
            setProductsData(orderResponse.data.data || []);
            setTotalProducts(orderResponse.data.total || 0);
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };
      fetchOrders();
    }
  }, [
    userid,
    page,
    limit,
    searchQuery,
    paymentStatus,
    orderStatus,
    refundedData
  ]);

  // Group orders by order_id
  const groupedOrders = productsData.reduce((acc, order) => {
    if (!acc[order.order_id]) acc[order.order_id] = [];
    acc[order.order_id].push(order);
    return acc;
  }, {});

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const handlePaymentStatusChange = (e) => {
    setPaymentStatus(e.target.value);
    setPage(1);
  };

  const handleOrderStatusChange = (e) => {
    setOrderStatus(e.target.value);
    setPage(1);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY HH:mm:ss");
  };

  const getStatusClass = (status, type) => {
    if (type === "payment") {
      return (
        {
          COMPLETED: "status-completed-delivered",
          PENDING: "status-pending",
          FAILED: "status-failed-cancelled"
        }[status] || "status-default"
      );
    }
    if (type === "order") {
      return (
        {
          Delivered: "status-completed-delivered",
          Pending: "status-pending",
          Cancelled: "status-failed-cancelled"
        }[status] || "status-default"
      );
    }
  };

  const cancelOrder = async (payment_id, price, inv_id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to cancel this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel it!",
        cancelButtonText: "No, keep it"
      });

      if (result.isConfirmed) {
        setIsLoading(true);

        const data = {
          payment_id: payment_id,
          refundableAmount: price,
          inv_id: inv_id
        };

        const CancelOrderResponse = await combinedServices.CancelOrder(data);

        if (CancelOrderResponse.data.status === 200) {
          setRefundedData("REFUNDED");
          Swal.fire(
            "Cancelled!",
            "Your order has been successfully cancelled.",
            "success"
          );
          setIsLoading(false);
        } else {
          Swal.fire("Failed!", "Failed to cancel the order.", "error");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      Swal.fire(
        "Error!",
        "An error occurred while cancelling the order.",
        "error"
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight myOrderCon">
        <div className="dbMid dbInner">
          <div className="dbMainHd">My Orders</div>
          {isLoading && (
            <div className="loadingOverlay">
              <div className="loadingSpinner"></div>
            </div>
          )}
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="filBtnWrp">
                    <label className="label2">Payment Status</label>
                    <select
                      value={paymentStatus}
                      onChange={handlePaymentStatusChange}
                      className="form_ctrl ctrlDrop"
                    >
                      <option value="">All Payment</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="PENDING">Pending</option>
                      <option value="FAILED">Failed</option>
                      <option value="REFUNDED">Refunded</option>
                    </select>
                    <span className="ent"></span>

                    <div className="srchGrp">
                      <label className="label2">Order Status</label>

                      <select
                        value={orderStatus}
                        onChange={handleOrderStatusChange}
                        className="form_ctrl ctrlDrop"
                      >
                        <option value="">All Orders</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Pending">Pending</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <span className="ent"></span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product Name and Order ID"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              {/* Table headers */}
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Product / Event Name</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Transaction ID</th>
                    <th>Ordered Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>

              <Accordion defaultActiveKey="0">
                {Object.keys(groupedOrders).map((orderId, idx) => {
                  const ordersWithSameId = groupedOrders[orderId];
                  const srNo = (page - 1) * limit + idx + 1;

                  return (
                    <Accordion.Item eventKey={String(idx)} key={orderId}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center">
                          <span className="me-3">
                            <strong>Sr. No.:</strong> {srNo}
                          </span>
                          <span className="me-3">
                            <strong>Order ID:</strong> {orderId}
                          </span>
                          <span>
                            <strong>Date:</strong>{" "}
                            {formatDate(ordersWithSameId[0].orderDate)}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <tbody>
                            {ordersWithSameId.map((order, index) => {
                              const attributes = JSON.parse(
                                order.attribute || "{}"
                              );
                              const productImages = (() => {
                                try {
                                  if (
                                    !order.product ||
                                    typeof order.product !== "object"
                                  )
                                    return [categoryIcon];
                                  if (
                                    !order.product.images ||
                                    order.product.images === "null"
                                  )
                                    return [categoryIcon];
                                  const parsedImages = JSON.parse(
                                    order.product.images
                                  );
                                  return Array.isArray(parsedImages) &&
                                    parsedImages.length > 0 &&
                                    parsedImages[0] !== null
                                    ? parsedImages
                                    : [categoryIcon];
                                } catch {
                                  return [categoryIcon];
                                }
                              })();

                              return (
                                <tr key={`${order.id}-${index}`}>
                                  <td className="td1">{index + 1}</td>
                                  <td className="td1">
                                    <div className="catPara">
                                      <img
                                        src={productImages[0] || ""}
                                        alt={order.product_name}
                                        className="productImage"
                                      />
                                      {order.product_name}
                                    </div>
                                  </td>
                                  <td className="td1">
                                    {Object.entries(attributes).map(
                                      ([key, value]) => {
                                        const displayValue = Array.isArray(
                                          value
                                        )
                                          ? value.join(", ")
                                          : value;

                                        return (
                                          <div key={key}>
                                            <strong>{key}:</strong>{" "}
                                            {displayValue}
                                          </div>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td className="td1"> ${order.price}</td>
                                  <td className="td1">{order.quantity}</td>
                                  <td className="td1">
                                    <span
                                      className={`status-badge ${getStatusClass(
                                        order.paymentStatus,
                                        "payment"
                                      )}`}
                                    >
                                      {order.paymentStatus}
                                    </span>
                                  </td>
                                  <td className="td1">
                                    <span
                                      className={`status-badge ${getStatusClass(
                                        order.orderStatus,
                                        "order"
                                      )}`}
                                    >
                                      {order.orderStatus}
                                    </span>
                                  </td>
                                  <td className="td1">{order.transaction_id || "N/A"}</td>
                                  <td>
                                    {formatDate(order.orderDate) || "N/A"}
                                  </td>
                                  <td className="td1">
                                    <button
                                      className={`status-badge ${
                                        order.inv_id !== 0 &&
                                        order.product !== null &&
                                        order.paymentStatus === "COMPLETED" &&
                                        order.orderStatus === "Pending"
                                          ? "status-failed-cancelled"
                                          : "status-default"
                                      }`}
                                      disabled={
                                        (order.inv_id === 0 &&
                                          order.product === null) ||
                                        order.paymentStatus !== "COMPLETED" ||
                                        order.orderStatus !== "Pending"
                                      }
                                      onClick={() => {
                                        cancelOrder(
                                          order.payment_id,
                                          order.price,
                                          order.inv_id
                                        );
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyOrders;
