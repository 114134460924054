import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Header from "../../../Components/adminDashboard/Header/Header";
import { useNavigate } from "react-router-dom";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import pencilIcon from "../../../assets/images/pencil.svg";
import deleteIcon from "../../../assets/images/bin.svg";
import combinedServices from "../../../shared/services/user-service";

const CategoryList = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [totalCategories, setTotalCategories] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteStatus, setdeleteStatus] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const totalPages = Math.ceil(totalCategories / limit);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let catagoryList = await combinedServices.getCategory(
          page,
          limit,
          searchQuery
        );
        if (catagoryList && catagoryList.data.categories) {
          setCategories(catagoryList.data.categories || []);
          setTotalCategories(catagoryList.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [page, limit, searchQuery, deleteStatus, isModalOpen]);

  const handleButtonClick = () => {
    navigate("/add-category");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const updateCatagories = async (category_id, category) => {
    try {
      if (!category_id || !category) {
        throw new Error("Category ID and name must be provided.");
      }

      const catagoryData = { category_id, category };
      console.log("asda", catagoryData);
      const updatedCatagory = await combinedServices.updateCategory(
        catagoryData
      );

      if (updatedCatagory?.status === 200) {
        setIsModalOpen(false);
        console.log(`Category with ID ${category_id} successfully updated.`);
        Swal.fire(
          "Updated!",
          updatedCatagory.data.message || "The category has been Updated.",
          "success"
        );
      } else {
        Swal.fire("Warning!", "Error in updation", "warning");
      }
    } catch (error) {
      Swal.fire("Warning!", "Error in updation", error, "warning");
    }
  };

  const deleteCatagory = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const response = await combinedServices.deleteCategory({
          category_id: id
        });
        console.log(response);
        if (response.data.status === 200) {
          setdeleteStatus(response.data.message);
          Swal.fire(
            "Deleted!",
            response.data.message || "The category has been deleted.",
            "success"
          );
        } else if (response.data.status === 400) {
          setdeleteStatus(response.data.message);
          Swal.fire(
            "Warning!",
            response.data.message || "This category has Connected to Products",
            "warning"
          );
        } else {
          Swal.fire(
            "Warning!",
            "The category may have been deleted, but an unexpected response was received.",
            "warning"
          );
        }
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      Swal.fire("Error!", "Failed to delete the category.", "error");
    }
  };

  const toggleCategoryStatus = async (id) => {
    try {
      const response = await combinedServices.changeStatus({ category_id: id });
      if (response.status === 200) {
        setdeleteStatus(response.data.message);
        Swal.fire(
          "Changed!",
          response.data.message || "The category has been deleted.",
          "success"
        );
      } else {
        Swal.fire(
          "Warning!",
          "The category status may have been Changes, but an unexpected response was received.",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire("Error!", "Failed to change status of category.", "error");
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Category Managment</div>
          <div className="catFilterWrp">
            <form>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    id="selectField"
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      id="searchField"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search Categories"
                    />
                  </div>
                </div>
              </div>
            </form>
            <button className="nambaBtn" onClick={handleButtonClick}>
              + Add New
            </button>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Category Name</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.length > 0 ? (
                    categories.map((category, index) => (
                      <tr key={category.category_id}>
                        <td>{(page - 1) * limit + index + 1}</td>
                        <td>
                          <div className="catPara">
                            <img
                              src={category.category_img || categoryIcon}
                              alt="categoryIcon"
                            />
                            {category.category}
                          </div>
                        </td>
                        <td>
                          {new Date(category.updatedOn).toLocaleDateString()}
                        </td>
                        <td>
                          <div className="tblBtnWrp">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={category.status}
                                onChange={() =>
                                  toggleCategoryStatus(category.category_id)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                            <button
                              className="Btn"
                              onClick={() => {
                                setSelectedCategory(category);
                                setIsModalOpen(true);
                              }}
                            >
                              <img src={pencilIcon} alt="pencilIcon" />
                            </button>
                            <button
                              className="Btn"
                              onClick={() =>
                                deleteCatagory(category.category_id)
                              }
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No categories available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <CategoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        updateCatagories={updateCatagories}
        category={selectedCategory}
      />
    </>
  );
};

export default CategoryList;

const CategoryModal = ({ isOpen, onClose, category, updateCatagories }) => {
  const [categoryName, setCategoryName] = useState("");
  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  useEffect(() => {
    if (category) {
      setCategoryName(capitalizeFirstLetter(category.category || ""));
    }
  }, [category]);

  if (!isOpen) return null;

  const handleSave = () => {
    if (category && category.category_id) {
      updateCatagories(category.category_id, categoryName);
    }
  };

  const handleInputChange = (e) => {
    setCategoryName(capitalizeFirstLetter(e.target.value));
  };

  return (
    <div className="catagorylistmodal">
      <div className="catagorylistmodal-content">
        <h2>Edit Category</h2>
        <br></br>
        <form className="category-form">
          <label htmlFor="category-name">Category Name</label>
          <input
            id="category-name"
            type="text"
            value={categoryName}
            onChange={handleInputChange}
            placeholder="Enter category name"
          />
          <div className="catagorylistmodal-actions">
            <div className="cancel-icon" onClick={onClose}>
              &#10006;
            </div>
            <button type="button" className="save-btn" onClick={handleSave}>
              Save
            </button>
          </div>
        </form>
      </div>
      <div className="catagorylistmodal-overlay" onClick={onClose}></div>
    </div>
  );
};
