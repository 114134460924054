import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import Swal from "sweetalert2";
import getLocalData from "../../../shared/localStorage/getData";
import userConstants from "../../../shared/constants/user-constants";

import Detail from "../../../assets/images/detail-img.jpg";
import Visa from "../../../assets/images/visa.png";
import Paypal from "../../../assets/images/paypal.png";
import "../Products/ProductListing.scss";
import CryptoJS from "crypto-js";
import swal from "sweetalert";

const AddToCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const [cartitem, setCartitem] = useState(cart);
  const [cartTicketitem, setCartticketitem] = useState([]);
  const [userid, setUserid] = useState();
  const [username, setUsername] = useState();
  const [validateCartStatus, setValidateCartStatus] = useState();
  const [validateCartErrors, setValidateCartStatusErrors] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const secretKey = userConstants.CRYPTOSECRETKEY;

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      let userData = getLocalData("user_info", "all").userData.login_user_detail
        .data.user;
      setUserid(userData.id);
      setUsername(userData.user_firstname + " " + userData.user_lastname);
    }

    localStorage.setItem("cartItems", JSON.stringify(cart));
    const encryptedEventData = localStorage.getItem("EventcartData");
    if (encryptedEventData) {
      const decryptedOrderId = CryptoJS.AES.decrypt(
        encryptedEventData,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const parsedDecryptedData = JSON.parse(decryptedOrderId);
      console.log("parsedDecryptedData", parsedDecryptedData);
      setCartticketitem(parsedDecryptedData);
    } else {
      console.log("Data not available in local storage");
    }
  }, [cart]);

  const encryptOrderId = (orderId) => {
    return CryptoJS.AES.encrypt(orderId, secretKey).toString();
  };

  const removeFromCart = (itemId) => {
    const updatedCart = cart.filter((item) => item.inv_id !== itemId);
    setCart(updatedCart);
    setCartitem(updatedCart);
    setValidateCartStatus();
    setValidateCartStatusErrors();
  };

  const updateQuantity = async (itemId, action) => {
    const updatedCart = cart.map((item) => {
      if (item.inv_id === itemId) {
        let updatedQuantity = item.quantity;

        if (action === "increment") {
          updatedQuantity += 1;
        } else if (action === "decrement" && updatedQuantity > 1) {
          updatedQuantity -= 1;
        }

        return { ...item, quantity: updatedQuantity };
      }
      return item;
    });

    const updatedItem = updatedCart.find((item) => item.inv_id === itemId);

    try {
      const quantityCheckResponse = await combinedServices.QuantityCheck({
        inv_id: updatedItem.inv_id,
        quantity: updatedItem.quantity
      });

      console.log(quantityCheckResponse.data.data);
      const isLastQuantity =
        quantityCheckResponse.data.data?.isLastQuantity || false;

      if (quantityCheckResponse.data.status === 200) {
        const finalUpdatedCart = updatedCart.map((item) =>
          item.inv_id === itemId ? { ...item, isLastQuantity } : item
        );

        setCart(finalUpdatedCart);
        setCartitem(finalUpdatedCart);
        setValidateCartStatus();
        setValidateCartStatusErrors();
        localStorage.setItem("cartitems", JSON.stringify(finalUpdatedCart));
      } else {
        console.error("Not enough quantity available");
        const finalUpdatedCart = updatedCart.map((item) =>
          item.inv_id === itemId ? { ...item, isLastQuantity: true } : item
        );

        setCart(finalUpdatedCart);
      }
    } catch (error) {
      console.error("Error checking quantity:", error);
    }
  };

  const goToProductPage = () => {
    navigate("/productListing");
  };

  const handleProductClick = (id) => {
    navigate(`/productDetail/${id}`);
  };

  const validateCart = async () => {
    try {
      setIsLoading(true);

      const eventIds = cartTicketitem.map((ticket) => ticket.event_id);
      const allSameEventId = eventIds.every((id) => id === eventIds[0]);

      if (!allSameEventId) {
        Swal.fire({
          icon: "warning",
          title: "Ticket Issue",
          text: "Please add tickets for only one event at a time."
        });
        setIsLoading(false);
        return;
      }

      const validatedData = await combinedServices.validateCart(cartitem);
      const allValid = validatedData.data.data.every(
        (item) => item.isValid === true
      );

      if (allValid) {
        setValidateCartStatus(validatedData.data.data);

        const totalAmount = (
          cart.reduce((total, item) => total + item.price * item.quantity, 0) +
          cartTicketitem.reduce(
            (total, user) => total + parseFloat(user.classWiseTotal || 0),
            0
          )
        ).toFixed(2);

        const saveorderData = {
          userId: userid,
          userName: username,
          items: cartitem,
          ticketdata: cartTicketitem
        };

        try {
          const createOrder = await combinedServices.createOrderinTable(
            saveorderData
          );
          const order_id = createOrder.data.data[0].order_id;
          const encryptedOrderId = encryptOrderId(order_id);

          localStorage.setItem("temp_orderId", encryptedOrderId);

          const ticketItems = Array.isArray(cartTicketitem)
            ? cartTicketitem.map((ticket) => ({
                name: ticket.event_name,
                price: parseFloat(ticket.classWiseTotal || "0").toFixed(2),
                inv_id: ticket.event_id,
                quantity: 1,
                attributes: {
                  Selected_Classes: Array.isArray(ticket.selectedClass)
                    ? ticket.selectedClass.map((cls) => cls.name).join(", ")
                    : "No Classes Selected", // Handle undefined case
                  Pit_Space: ticket.pit_space ? "Yes" : "No",
                  Special_Note: ticket.special_note || "None"
                },
                image: ticket.image || "",
                product_type: "event_ticket"
              }))
            : [];

          // Merge both cart items and ticket items
          const mergedCartItems = [...cartitem, ...ticketItems];
          console.log("ticketdata", mergedCartItems, cartTicketitem);
          const orderData = {
            items: mergedCartItems,
            total: totalAmount,
            orderId: order_id
          };

          // await handlePaymentMethod(order_id);

          const apiResponse = await combinedServices.shoppingCartPaypalpayment(
            orderData
          );

          if (apiResponse.data.status === 200) {
            const approvalUrl = apiResponse.data.data.approvalUrl;
            if (approvalUrl) {
              window.location.href = approvalUrl;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Order Failed",
              text: "There was an error creating your order."
            });
          }
        } catch (error) {
          console.error("Error creating order:", error);
          Swal.fire({
            icon: "error",
            title: "Order Failed",
            text: "An unexpected error occurred while processing your order."
          });
        }
      } else {
        const invalidItems = validatedData.data.data.filter(
          (item) => item.isValid === false
        );

        setValidateCartStatusErrors(invalidItems);
        const errorMessages = invalidItems
          .map(
            (item) =>
              `<li><strong>${item.product_name}:</strong> ${item.message}</li> <br>`
          )
          .join("");

        Swal.fire({
          icon: "warning",
          title: "Cart Issue",
          html: `<ul style="list-style-type: none; padding-left: 0;">${errorMessages}</ul>`,
          showCloseButton: true,
          focusConfirm: false
        });
      }
    } catch (error) {
      console.error("Error validating cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error validating the cart."
      });
    } finally {
      setIsLoading(false); // Stop loader once everything is done
    }
  };

  const removeFromTicketCart = (userId, eventName) => {
    const encryptedEventData = localStorage.getItem("EventcartData");
    let updatedCart = [];

    if (encryptedEventData) {
      try {
        const decryptedData = CryptoJS.AES.decrypt(
          encryptedEventData,
          secretKey
        ).toString(CryptoJS.enc.Utf8);
        updatedCart = JSON.parse(decryptedData) || [];
      } catch (error) {
        console.error("Error decrypting localStorage data:", error);
        updatedCart = [];
      }
    }

    // 🔹 Remove entry with matching `user_id` and `event_name`
    updatedCart = updatedCart.filter(
      (item) => !(item.user_id === userId && item.event_name === eventName)
    );

    if (updatedCart.length > 0) {
      // 🔹 Encrypt and update localStorage only if the array is not empty
      const encryptedUpdatedData = CryptoJS.AES.encrypt(
        JSON.stringify(updatedCart),
        secretKey
      ).toString();
      localStorage.setItem("EventcartData", encryptedUpdatedData);
    } else {
      // 🔹 Remove `EventcartData` from localStorage if the array is empty
      localStorage.removeItem("EventcartData");
    }

    // 🔹 Update state
    setCartticketitem(updatedCart);
    setValidateCartStatus();
    setValidateCartStatusErrors();
  };

  const handleEnterEvent = (id) => {
    navigate("../eventTicket/" + id);
  };

  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="mainCont">
          <section className="ContactOuter pt-5 pb-5">
            <div className="container">
              <div>
                <div className="mb-3 productHd">
                  <span>YOUR CART</span>
                </div>
                {isLoading && (
                  <div className="loadingOverlay">
                    <div className="loadingSpinner"></div>
                  </div>
                )}

                {cart.length || cartTicketitem.length > 0 ? (
                  <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-7 cartInfo">
                      {cart.map((item) => (
                        <div
                          key={item.id}
                          className="inner p-3 p-sm-4 d-flex flex-wrap"
                        >
                          <div
                            className="img"
                            onClick={() => handleProductClick(item.id)}
                          >
                            <img
                              src={
                                item.image ? JSON.parse(item.image)[0] : Detail
                              }
                              alt={item.name}
                            />
                          </div>
                          <div className="det ps-s ps-sm-4 pt-3 pt-sm-0">
                            <div className="name mb-3">{item.name}</div>
                            <div className="price mb-4">
                              ${item.price} USD{" "}
                              {/* <span className="oldPrice d-block mt-1">
                                ${item.price.toFixed(2)} USD
                              </span> */}
                            </div>
                            {/* Display attributes in a single line */}
                            <div className="attributes mb-4">
                              <div className="attributesList">
                                {Object.keys(item.attributes).map(
                                  (key, index) => (
                                    <span key={index} className="attributeItem">
                                      <strong>{key}:</strong>{" "}
                                      {item.attributes[key]}
                                      {index <
                                        Object.keys(item.attributes).length -
                                          1 && ", "}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>

                            <div className="cartActions d-flex align-items-center mb-4">
                              {/* Quantity Control */}
                              <div className="quantityControl d-flex align-items-center">
                                <button
                                  className="quantityBtn"
                                  onClick={() =>
                                    updateQuantity(item.inv_id, "decrement")
                                  }
                                  disabled={item.quantity === 1} // Disable if quantity is 1
                                >
                                  -
                                </button>
                                <span className="quantityDisplay mx-2">
                                  {item.quantity}
                                </span>
                                <button
                                  className="quantityBtn"
                                  onClick={() =>
                                    updateQuantity(item.inv_id, "increment")
                                  }
                                  disabled={item.isLastQuantity} // Disable when isLastQuantity is true
                                >
                                  +
                                </button>
                              </div>

                              {/* Remove Item */}
                              <a
                                href="#"
                                className="removeLink"
                                onClick={() => removeFromCart(item.inv_id)}
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                      {cartTicketitem?.map((user) => (
                        <div
                          key={user.user_id}
                          className="inner p-3 p-sm-4 d-flex flex-wrap"
                        >
                          <div
                            className="img"
                            onClick={() => handleEnterEvent(user.event_id)}
                          >
                            <img
                              src={
                                user.image &&
                                user.image.startsWith("https://nambaweb.s3")
                                  ? user.image
                                  : Detail
                              }
                              alt={user.user_name}
                            />
                          </div>
                          <div className="det ps-s ps-sm-4 pt-3 pt-sm-0">
                            <div className="price mb-3">
                              Event Name - {user.event_name}
                            </div>
                            <div className="mb-3">
                              <strong>User Name</strong> - {user.user_name}
                            </div>
                            <div className="price mb-4">
                              ${user.classWiseTotal} USD
                            </div>
                            <div className="mb-4">
                              <strong>Special Note - </strong>{" "}
                              {user.special_note || "N/A"}
                            </div>

                            {/* Display selected classes or transponders */}
                            <div className="attributes mb-4">
                              <div className="attributesList">
                                <strong>
                                  {user.selectedClass.some(
                                    (classItem) => classItem.transponder_name
                                  )
                                    ? "Event's Transponders"
                                    : "Event's Classes"}
                                </strong>
                                &nbsp;-&nbsp;
                                {user.selectedClass.map((classItem, index) => (
                                  <span key={index} className="attributeItem">
                                    {/* Check for name, transponder_name, or default to "N/A" */}
                                    {classItem.transponder_name ||
                                      classItem.name ||
                                      "N/A"}
                                    {index !== user.selectedClass.length - 1 &&
                                      ", "}
                                  </span>
                                ))}
                              </div>
                            </div>

                            <div className="cartActions d-flex align-items-center mb-4">
                              <a
                                href="#"
                                className="removeLink"
                                onClick={() =>
                                  removeFromTicketCart(
                                    user.user_id,
                                    user.event_name
                                  )
                                }
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 col-lg-5 payOption pt-4 pt-lg-0 ps-0 ps-lg-5">
                      {/* Payment section */}
                      <div className="hd mb-3">How you'll pay</div>
                      <div className="mb-4">
                        <label className="d-flex align-items-center mt-2">
                          <span className="radioWrapper">
                            <input
                              type="radio"
                              name="payment"
                              className="radioInpt"
                              checked
                            />
                            <i className="bg"></i>
                          </span>{" "}
                          <img src={Paypal} alt="" className="ms-3" />
                          <img src={Visa} alt="" className="ms-3" />
                        </label>
                      </div>
                      <div>
                        <ul className="payDetail p-0 m-0">
                          <li className="d-flex justify-content-between pb-2">
                            <span>Item(s) total</span>
                            <span>
                              $
                              {cart
                                .reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-2">
                            <span>Tickets(s) total</span>
                            <span>
                              $
                              {cartTicketitem
                                .reduce(
                                  (total, user) =>
                                    total +
                                    parseFloat(user.classWiseTotal || 0),
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-3">
                            {/* Optionally, add more details here */}
                          </li>
                          {/* Combine both carts for Subtotal */}
                          <li className="d-flex justify-content-between pb-2 pt-3 bor">
                            <span>Subtotal</span>
                            <span>
                              $
                              {(
                                cart.reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                ) +
                                cartTicketitem.reduce(
                                  (total, user) =>
                                    total +
                                    parseFloat(user.classWiseTotal || 0),
                                  0
                                )
                              ).toFixed(2)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-3">
                            <span>Delivery</span>
                            <span>$ 0.00</span>
                          </li>
                          {/* Combine Subtotal with Delivery (if needed) for Total */}
                          <li className="d-flex justify-content-between pb-2 pt-3 bor">
                            <span>
                              Total (
                              {cart.reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}{" "}
                              items)
                            </span>
                            <span>
                              $
                              {(
                                cart.reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                ) +
                                cartTicketitem.reduce(
                                  (total, user) =>
                                    total +
                                    parseFloat(user.classWiseTotal || 0),
                                  0
                                )
                              ).toFixed(2)}
                            </span>
                          </li>
                        </ul>
                        <div className="mt-4">
                          <a onClick={validateCart} className="checkoutBtn">
                            Proceed to Checkout
                          </a>
                        </div>
                        {/* Optional note */}
                        {/* <div className="mt-4 notetext">
      Local taxes included (where applicable)
      <br />* Learn more about additional taxes, duties, and fees that may apply
    </div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="emptyCart">
                    <h2>Your Cart is Empty</h2>
                    <p>
                      Looks like you haven't added anything to your cart yet.
                    </p>
                    <button
                      className="goToProductPageBtn"
                      onClick={goToProductPage}
                    >
                      Go to Product Page
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddToCart;
