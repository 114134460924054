import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import Accordion from "react-bootstrap/Accordion";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import moment from "moment";
import Swal from "sweetalert2";

const OrderManagment = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [updatedOrderStatus, setUpdatedOrderStatus] = useState("");
  const [refundedData, setRefundedData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const totalPages = Math.ceil(totalOrders / limit);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderResponse = await combinedServices.getallorders(
          page,
          limit,
          searchQuery,
          paymentStatus,
          orderStatus
        );
        if (orderResponse?.data?.data) {
          setOrderData(orderResponse.data.data || []);
          setTotalOrders(orderResponse.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, [
    page,
    limit,
    searchQuery,
    paymentStatus,
    orderStatus,
    updatedOrderStatus,
    refundedData
  ]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const handlePaymentStatusChange = (e) => {
    setPaymentStatus(e.target.value);
    setPage(1);
  };

  const handleOrderStatusChange = (e) => {
    setOrderStatus(e.target.value);
    setPage(1);
  };

  const groupedOrders = orderData.reduce((acc, order) => {
    if (!acc[order.order_id]) acc[order.order_id] = [];
    acc[order.order_id].push(order);
    return acc;
  }, {});

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY HH:mm:ss");
  };

  const updateOrderstatus = async (id, status) => {
    try {
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: `You are about to update the order status to "${status}".`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "No, cancel"
      });

      if (!confirmResult.isConfirmed) {
        return;
      }

      Swal.fire({
        title: "Updating...",
        text: "Please wait while we update the order status.",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        timerProgressBar: true
      });

      const statusData = { id, status };
      const orderUpdateResponse = await combinedServices.updateOrderstatus(
        statusData
      );

      if (orderUpdateResponse?.data) {
        setUpdatedOrderStatus(orderUpdateResponse.data || []);

        Swal.fire({
          title: "Success!",
          text: orderUpdateResponse.data.message,
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Failed to update the order status. Please try again.",
          icon: "error"
        });
      }
    } catch (error) {
      console.error("Error Updating orders:", error);

      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating the order status.",
        icon: "error"
      });
    }
  };

  const getStatusClass = (status, type) => {
    if (type === "payment") {
      return (
        {
          COMPLETED: "status-completed-delivered",
          PENDING: "status-pending",
          FAILED: "status-failed-cancelled"
        }[status] || "status-default"
      );
    }
    if (type === "order") {
      return (
        {
          Delivered: "status-completed-delivered",
          Pending: "status-pending",
          Cancelled: "status-failed-cancelled"
        }[status] || "status-default"
      );
    }
  };

  const cancelOrder = async (payment_id, price, inv_id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to cancel this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel it!",
        cancelButtonText: "No, keep it"
      });

      if (result.isConfirmed) {
        setIsLoading(true);

        const data = {
          payment_id: payment_id,
          refundableAmount: price,
          inv_id: inv_id
        };

        const CancelOrderResponse = await combinedServices.CancelOrder(data);
        console.log("CancelOrderResponse", CancelOrderResponse.data.status);

        if (CancelOrderResponse.data.status === 200) {
          setRefundedData("REFUNDED");
          Swal.fire(
            "Cancelled!",
            "Your order has been successfully cancelled.",
            "success"
          );
          setIsLoading(false);
        } else {
          Swal.fire("Failed!", "Failed to cancel the order.", "error");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      Swal.fire(
        "Error!",
        "An error occurred while cancelling the order.",
        "error"
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
        {isLoading && (
                  <div className="loadingOverlay">
                    <div className="loadingSpinner"></div>
                  </div>
                )}
          <div className="dbMainHd">Order Management</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="filBtnWrp">
                    <label className="label2">Payment Status</label>
                    <select
                      value={paymentStatus}
                      onChange={handlePaymentStatusChange}
                      className="form_ctrl ctrlDrop"
                    >
                      <option value="">All Payment</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="PENDING">Pending</option>
                      <option value="FAILED">Failed</option>
                    </select>
                    <span className="ent"></span>

                    <div className="srchGrp">
                      <label className="label2">Order Status</label>

                      <select
                        value={orderStatus}
                        onChange={handleOrderStatusChange}
                        className="form_ctrl ctrlDrop"
                      >
                        <option value="">All Orders</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Pending">Pending</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <span className="ent"></span>

                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Order ID, Product Name or User Name"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              {/* Table headers */}
              <table>
                <thead>
                  <tr>
                    <th className="orderTableminweidth">User Name</th>
                    <th className="orderTableminweidth">
                      Product / Event Name
                    </th>
                    <th className="orderTableminweidth">Description</th>
                    <th className="orderTableminweidth">Quantity</th>
                    <th className="orderTableminweidth">Price</th>
                    <th className="orderTableminweidth">Total</th>
                    <th className="orderTableminweidth">Payment Status</th>
                    <th className="orderTableminweidth">Order Status</th>
                    <th className="orderTableminweidth">Transaction ID</th>
                    <th className="orderTableminweidth">Ordered Date</th>
                    <th className="orderTableminweidth">Action</th>
                  </tr>
                </thead>
              </table>
              <Accordion defaultActiveKey="0">
                {Object.keys(groupedOrders).map((orderId, idx) => {
                  const ordersWithSameId = groupedOrders[orderId];
                  return (
                    <Accordion.Item eventKey={String(idx)} key={orderId}>
                      <Accordion.Header>
                        <strong style={{ marginRight: "10px" }}>
                          Order ID:
                        </strong>{" "}
                        {orderId}{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <tbody>
                            {ordersWithSameId.map((order) => {
                              const product = order.product || {};
                              const images = JSON.parse(order.image || "[]");
                              const orderAttributes = JSON.parse(
                                order.attribute || "{}"
                              );
                              const totalPrice =
                                order.quantity * order.unit_price;

                              return (
                                <tr key={order.id}>
                                  <td className="orderTableminweidth">
                                    {order.username}
                                  </td>
                                  <td className="orderTableminweidth">
                                    <div className="catPara">
                                      <img
                                        src={images[0] || categoryIcon}
                                        alt={
                                          order.product_name || "Product Image"
                                        }
                                      />
                                      {order.product_name || "N/A"}
                                    </div>
                                  </td>
                                  <td className="orderTableminweidth">
                                    {Object.keys(orderAttributes).length > 0 ? (
                                      Object.entries(orderAttributes).map(
                                        ([key, value]) => (
                                          <div key={key}>
                                            <strong>{key}:</strong>{" "}
                                            {Array.isArray(value)
                                              ? value.join(", ")
                                              : value}
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </td>

                                  <td className="orderTableminweidth">
                                    {order.quantity}
                                  </td>
                                  <td className="orderTableminweidth">
                                    ${order.unit_price || "N/A"}
                                  </td>
                                  <td className="orderTableminweidth">
                                    ${totalPrice.toFixed(2)}
                                  </td>
                                  <td>
                                    <span
                                      className={`status-badge ${getStatusClass(
                                        order.paymentStatus,
                                        "payment"
                                      )}`}
                                    >
                                      {order.paymentStatus}
                                    </span>
                                  </td>
                                  <td className="orderTableminweidth">
                                    <div className="orderStatus ">
                                      <select
                                        value={order.orderStatus || "pending"}
                                        onChange={(e) =>
                                          updateOrderstatus(
                                            order.id,
                                            e.target.value
                                          )
                                        }
                                        className={`form_ctrl ctrlDrop ${getStatusClass(
                                          order.orderStatus,
                                          "order"
                                        )}`}
                                      >
                                        <option value="Delivered">
                                          DELIVERED
                                        </option>
                                        <option value="Pending">PENDING</option>
                                        <option value="Cancelled">
                                          CANCELLED
                                        </option>
                                      </select>
                                    </div>
                                  </td>
                                  <td className="orderTableminweidth">
                                    {order.transaction_id || "N/A"}
                                  </td>
                                  <td className="orderTableminweidth">
                                    {formatDate(order.orderDate) || "N/A"}
                                  </td>
                                  <td>
                                    <button
                                      className={`status-badge ${
                                        order.inv_id !== 0 &&
                                        order.product !== null &&
                                        order.paymentStatus === "COMPLETED" &&
                                        order.orderStatus === "Cancelled"
                                          ? "status-failed-cancelled"
                                          : "status-default"
                                      }`}
                                      disabled={
                                        (order.inv_id === 0 &&
                                          order.product === null) ||
                                        order.paymentStatus !== "COMPLETED" ||
                                        order.orderStatus !== "Cancelled"
                                      }
                                      onClick={() => {
                                        cancelOrder(
                                          order.payment_id,
                                          order.price,
                                          order.inv_id
                                        );
                                      }}
                                    >
                                      Initiate Refund
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>

            {/* Pagination */}
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderManagment;
