import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import "./SuccessPage.scss";
import combinedServices from "../../../shared/services/user-service";
import CryptoJS from "crypto-js";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import userConstants from "../../../shared/constants/user-constants";

const EventUpdatePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setCartState = useSetRecoilState(cartState);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cartTicketitem, setCartticketitem] = useState([]);
  const [tranctationId, setTranctationId] = useState("");
  const [payerId, setPayerID] = useState("");

  useEffect(() => {
    const capturePayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      const PayerID = searchParams.get("PayerID");
      setPayerID(PayerID);

      const encryptedEventitemData = localStorage.getItem("UpdateEventsData");
      const secretKey = userConstants.CRYPTOSECRETKEY;

      if (!encryptedEventitemData) {
        console.error("Error: No UpdateEventsData found in localStorage.");
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }

      let decryptedEventcartitem = [];
      try {
        const decryptedEventcartitems = CryptoJS.AES.decrypt(
          encryptedEventitemData,
          secretKey
        ).toString(CryptoJS.enc.Utf8);

        decryptedEventcartitem = JSON.parse(decryptedEventcartitems);
        console.log("Decrypted Data:", decryptedEventcartitem);
      } catch (error) {
        console.error("Error decrypting EventcartData:", error);
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }

      setCartticketitem(decryptedEventcartitem);

      if (!token || !PayerID) {
        console.error("Missing token or PayerID.");
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }

      try {
        const response = await combinedServices.captureupdateeventpayment(
          token,
          PayerID
        );

        if (response && response.data && response.data.status === 200) {
          console.log("Payment captured successfully:", response.data);
          setTranctationId(response.data.data.transactionId);
          setPayerID(response.data.data.payerId);

          handleSubmitEntries(
            decryptedEventcartitem.unselectedClasses,
            decryptedEventcartitem.selectedClasses,
            decryptedEventcartitem.transponder,
            decryptedEventcartitem.event_participant_id,
            decryptedEventcartitem.transponderData,
            decryptedEventcartitem.participantId,
            response.data.data.rawResponse.id,
            response.data.data.payerId,
            decryptedEventcartitem.newPayment,
            decryptedEventcartitem.unselectedClassesName,
            decryptedEventcartitem.selectedClassesName,
            decryptedEventcartitem.eventID,
            decryptedEventcartitem.userID
          );

          setPaymentStatus("success");
          setCartState([]);
          localStorage.removeItem("UpdateEventsData");
        } else {
          console.error("No payment details found or payment failed.");
          setPaymentStatus("failed");
        }
      } catch (error) {
        console.error("Error capturing payment:", error);
        setPaymentStatus("failed");
      } finally {
        setIsLoading(false);
      }
    };

    capturePayment();
  }, [location.search, setCartState]);

  const handleSubmitEntries = async (
    unselectedClasses,
    selectedClasses,
    transponder,
    participateId,
    transponderData,
    participantId,
    tranctation,
    payerid,
    newPayment,
    unselectedClassesName,
    selectedClassesName,
    eventID,
    userID
  ) => {
    var paymentDataArr = [];

    var paymentdata = {
      event_participant_id: participateId,
      payment_for: "event_participation",
      pit_space: false,
      payment_type: "PAYPAL",
      payer_id: payerid,
      payment_id: tranctation,
      amount: newPayment
    };
    paymentDataArr.push(paymentdata);

    const eventorderData = {
      userID,
      eventID,
      selectedClassesName,
      unselectedClassesName,
      newPayment,
      payerid,
      tranctation
    };

    console.log(
      "selectedClassesName",
      selectedClassesName,
      "unselectedClassesName",
      unselectedClassesName
    );
    // console.log("eventName", eventID, "userID", userID);
    // console.log("updateEventOrderData", eventorderData);
    // return;
    let updateEventOrderData = await combinedServices.UpdateEventOrderData(
      eventorderData
    );
    let eventMailsResponse = await combinedServices.shopEventaddPaymentMany(
      paymentDataArr
    );

    const updatenewbalance = {
      event_participant_id: participateId,
      newAmount: parseFloat(newPayment)
    };

    let updateeventparticipant =
      await combinedServices.updatebalanceeventparticipant(updatenewbalance);

    if (unselectedClasses.length > 0) {
      let data = {
        ids: unselectedClasses
      };
      let eventClassResponse =
        await combinedServices.cancleEventParticipateClassMany(data);
    }

    if (selectedClasses.length > 0) {
      if (transponder) {
        let eventClassResponse =
          await combinedServices.addEventParticipateClassMany(transponderData);
      } else {
        let data = {
          class_ids: selectedClasses,
          event_participant_id: Number(participateId)
            ? Number(participateId)
            : participantId
        };
        let eventClassResponse =
          await combinedServices.addEventParticipateClassMany(data);
      }
    }
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleViewOrders = () => {
    navigate("/MyOrders");
  };

  return (
    <>
      <Header />
      <div className="success-page">
        <div className="success-container">
          {isLoading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>
                Processing your payment. Please do not go back or exit this
                window.
              </p>
            </div>
          ) : (
            <>
              <div className="icon-container">
                {paymentStatus === "success" ? (
                  <span className="check-icon success">✔</span>
                ) : (
                  <span className="check-icon failed">✘</span>
                )}
              </div>
              {paymentStatus === "success" ? (
                <>
                  <h1>Payment Successful!</h1>
                  <p>
                    Thank you for your purchase. Your order has Updated
                    successfully.
                  </p>
                  <div className="order-confirmation">
                    <p>We hope to see you again!</p>
                  </div>
                </>
              ) : (
                <>
                  <h1>Payment Failed</h1>
                  <p>
                    {localStorage.getItem("UpdateEventsData") === null
                      ? "❌ Error: No UpdateEventsData found. Please try again."
                      : "Unfortunately, your payment could not be processed. Please try again."}
                  </p>
                </>
              )}
              <div className="button-group">
                {paymentStatus === "success" ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={handleViewOrders}
                    >
                      View Orders
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleGoHome}
                    >
                      Go to Home
                    </button>
                  </>
                ) : (
                  <button className="btn btn-secondary" onClick={handleGoHome}>
                    Go to Home
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventUpdatePage;
