import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import "../CatagoryManagment/AdminNew.scss";
import uploadIcon from "../../../assets/images/upload_icon_blue.svg";
import Swal from "sweetalert2";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddProduct = () => {
  const [categories, setCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [adminid, setAdminid] = useState();
  const [formData, setFormData] = useState({
    category_id: "",
    product_name: "",
    sku_code: "",
    retail_price: "",
    cost_price: ""
  });
  const [attributeformData, setAttributeformData] = useState({});
  const [attributeValues, setAttributeValues] = useState({});

  const [uploadedImages, setUploadedImages] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTextareaValue(data);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let categoryList = await combinedServices.getCategory();
        if (categoryList && categoryList.data.categories) {
          const activeCategories = categoryList.data.categories.filter(
            (category) => category.status === true
          );

          setCategories(activeCategories || []);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (getLocalData("admin_info", "isLogin")) {
      let adminId = getLocalData("admin_info", "all").userData.user.id;
      setAdminid(adminId);
    }

    fetchCategories();
  }, []);

  const getAttributes = async (category_id) => {
    try {
      let attributesList = await combinedServices.getAttributewithCatId(
        category_id
      );

      if (attributesList && attributesList.data.data) {
        const attributes = attributesList.data.data || [];
        setAttributes(attributes);

        if (attributes.length === 0) {
          Swal.fire({
            title: "No Attributes Found",
            text: "No attributes found for the selected category.",
            icon: "info",
            confirmButtonText: "OK"
          });
        }
      } else {
        Swal.fire({
          title: "No Attributes Found",
          text: "No attributes found for the selected category.",
          icon: "info",
          confirmButtonText: "OK"
        });
        setAttributes([]);
      }
    } catch (error) {
      console.error("Error fetching Attributes:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch attributes. Please try again later.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  const handleAttributeInputChange = (attributeId, value) => {
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setAttributeformData((prev) => ({
      ...prev,
      [attributeId]: capitalizedValue
    }));
  };

  const handleAttributeKeyDown = (attributeId, event) => {
    if (event.key === "Enter" && attributeformData[attributeId]?.trim()) {
      event.preventDefault();
      setAttributeValues((prev) => ({
        ...prev,
        [attributeId]: [
          ...(prev[attributeId] || []),
          attributeformData[attributeId]
        ]
      }));
      setAttributeformData((prev) => ({
        ...prev,
        [attributeId]: ""
      }));
    }
  };

  const handleRemoveValue = (attributeId, valueToRemove) => {
    setAttributeValues((prev) => {
      const updatedValues =
        prev[attributeId]?.filter((value) => value !== valueToRemove) || [];
      return {
        ...prev,
        [attributeId]: updatedValues
      };
    });
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === "category_id") {
      getAttributes(value);
    }
  };

  // Handle multiple file uploads and upload to the server
  const handleFileChange = async (e) => {
    const files = e.target.files;
    const newImages = [...uploadedImages];
    const maxSize = 2 * 1024 * 1024;
    const allowedTypes = ["image/jpeg", "image/png"];

    // Check if we already have 3 images
    if (uploadedImages.length >= 3) {
      Swal.fire({
        icon: "warning",
        title: "Maximum Images Reached",
        text: "You cannot upload more than 3 images."
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        alert("Only JPG and PNG images are allowed.");
        continue;
      }

      if (file.size > maxSize) {
        alert("File size must be less than 2 MB.");
        continue;
      }

      // Ensure that the total images do not exceed 3
      if (newImages.length >= 3) {
        Swal.fire({
          icon: "warning",
          title: "Maximum Images Reached",
          text: "You cannot upload more than 3 images."
        });
        break;
      }

      const formData = new FormData();
      formData.append("image", file);

      try {
        const uploadResponse = await combinedServices.uploadImageFromAdmin(
          formData
        );

        if (uploadResponse.status === 200) {
          const imageUrl = uploadResponse.data.location;
          newImages.push(imageUrl);
        } else {
          alert("Failed to upload image. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image.");
      }
    }

    setUploadedImages(newImages);
  };

  const removeImage = (imageToRemove) => {
    const filteredImages = uploadedImages.filter(
      (image) => image !== imageToRemove
    );
    setUploadedImages(filteredImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.type) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please select a Type (Inventory or Non-Inventory)."
      });
      return;
    }

    if (!formData.category_id) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please select a Category."
      });
      return;
    }

    if (!formData.product_name.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Product Name cannot be empty."
      });
      return;
    }

    if (formData.product_name.length > 100) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Product Name cannot exceed 100 Characters."
      });
      return;
    }

    if (!textareaValue.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Description cannot be empty."
      });
      return;
    }

    if (textareaValue.length > 10000) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Description cannot exceed 10000 Characters."
      });
      return;
    }

    if (!formData.retail_price || parseFloat(formData.retail_price) < 0) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please enter a valid Retail Price."
      });
      return;
    }

    if (!formData.cost_price || parseFloat(formData.cost_price) < 0) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please enter a valid Cost Price."
      });
      return;
    }

    if (!formData.sku_code.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "SKU Code cannot be empty."
      });
      return;
    }

    if (uploadedImages.length < 1 || uploadedImages.length > 3) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please upload at least 1 and at most 3 product images."
      });
      return;
    }

    let isValid = true;
    let missingAttributes = [];

    attributes.forEach((attribute) => {
      const values = attributeValues[attribute.attribute_id] || [];
      if (values.length === 0) {
        isValid = false;
        missingAttributes.push(attribute.attributes);
      }
    });

    if (!isValid) {
      Swal.fire({
        icon: "warning",
        title: "Missing Attribute Values",
        text: `Please fill in the following mandatory attributes: ${missingAttributes.join(
          ", "
        )}`
      });
      return;
    }

    const formattedAttributes = {};
    attributes.forEach((attribute) => {
      const attributeValuesArray =
        attributeValues[attribute.attribute_id] || [];
      formattedAttributes[Number(attribute.attribute_id)] =
        attributeValuesArray;
    });

    const formDataToSubmit = {
      ...formData,
      description: textareaValue,
      attributes: formattedAttributes,
      images: uploadedImages,
      addedby_admin_id: adminid
    };

    try {
      const response = await combinedServices.addProduct(formDataToSubmit);
      if (response && response.data.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Product Added",
          text: response.data.message
        });
        // Reset fields after successful submission
        setFormData({
          category_id: "",
          product_name: "",
          sku_code: "",
          retail_price: "",
          cost_price: ""
        });
        setTextareaValue("");
        setUploadedImages([]);
        setAttributeValues({});
        setAttributeformData({});
        setAttributes([]);
      } else {
        throw new Error(response.data.error || "Failed to add product");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "There was an error adding the product."
      });
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Add Product</div>
          <div className="whtBoxWrpOuter">
            <form onSubmit={handleSubmit}>
              <div className="whtBoxWrpper">
                {/* left box starts here */}
                <div className="wbx_Cont wbx_left">
                  <div className="form_grpWrp">
                    <label htmlFor="inventoryField">
                      Product Type <span className="req">*</span>
                    </label>
                    <div className="form_grp">
                      <select
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        className="form_ctrl cusSel"
                      >
                        <option value="">Select Type</option>
                        <option value="inventory">Inventory</option>
                        <option value="non_inventory">Non-Inventory</option>
                      </select>
                    </div>
                  </div>
                  <div className="form_grpWrp">
                    <label htmlFor="selectField">
                      Category <span className="req">*</span>
                    </label>
                    <div className="form_grp">
                      <select
                        id="category_id"
                        name="category_id"
                        value={formData.category_id}
                        onChange={handleChange}
                        className="form_ctrl cusSel"
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Product Name and Description */}
                  <div className="form_grpWrp">
                    <label htmlFor="textField">
                      Product Name <span className="req">*</span>{" "}
                    </label>
                    <div className="form_grp">
                      <input
                        type="text"
                        id="product_name"
                        name="product_name"
                        value={formData.product_name}
                        onChange={handleChange}
                        className="form_ctrl"
                        placeholder="Enter Product Name"
                      />
                      <span className="errormsg">
                        Do not exceed 100 Characters when entering the product
                        name.
                      </span>
                    </div>
                  </div>

                  <div className="form_grpWrp">
                    <label htmlFor="selectField">
                      Description <span className="req">*</span>
                    </label>
                    <div className="form_grp">
                      <CKEditor
                        editor={ClassicEditor}
                        data={textareaValue}
                        onChange={handleEditorChange}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "undo",
                            "redo"
                          ]
                        }}
                      />
                      <span className="errormsg">
                        Do not exceed 10000 characters when entering the
                        Description.
                      </span>
                    </div>

                    <div
                      className="form_grpWrp"
                      style={{ display: "flex", gap: "1rem" }}
                    >
                      {/* Retail Price */}
                      <div className="form_grpWrp" style={{ flex: 1 }}>
                        <label htmlFor="retail_price">
                          Retail Price <span className="req">*</span>
                        </label>
                        <div className="form_grp">
                          <input
                            type="number"
                            id="retail_price"
                            name="retail_price"
                            value={formData.retail_price}
                            onChange={handleChange}
                            className="form_ctrl"
                            placeholder="Enter Retail Price"
                            min="0"
                            step="0.01"
                            onKeyPress={(e) => {
                              if (
                                e.key === "-" ||
                                e.key === "e" ||
                                e.key === "E"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="errormsg">
                            Enter the Retail Price of the product
                          </span>
                        </div>
                      </div>
                      {/* Cost Price */}
                      <div className="form_grpWrp" style={{ flex: 1 }}>
                        <label htmlFor="cost_price">
                          Cost Price <span className="req">*</span>
                        </label>
                        <div className="form_grp">
                          <input
                            type="number"
                            id="cost_price"
                            name="cost_price"
                            value={formData.cost_price}
                            onChange={handleChange}
                            className="form_ctrl"
                            placeholder="Enter Cost Price"
                            min="0"
                            step="0.01"
                            onKeyPress={(e) => {
                              if (
                                e.key === "-" ||
                                e.key === "e" ||
                                e.key === "E"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="errormsg">
                            Enter the Cost price of the product
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* right box starts here */}
                <div className="wbx_Cont wbx_right">
                  <div className="form_grpWrp">
                    <label htmlFor="selectField">Upload Images</label>
                    <div className="upldCont">
                      <div className="custom-file-input-container form_grp cusImgUpld">
                        <label className="custom-file-label">
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            className="custom-file-input"
                          />
                          <div className="custom-file-button">
                            <img src={uploadIcon} alt="upload icon" />
                            <p>
                              Drop your images here or select{" "}
                              <span>click to browse</span>
                            </p>
                          </div>
                        </label>
                      </div>

                      {/* Dynamically rendering the uploaded images */}
                      {uploadedImages.length === 0
                        ? ""
                        : uploadedImages.map((image, index) => (
                            <div
                              key={index}
                              className="uploaded-images-container"
                            >
                              <div className="uploadedImgW">
                                <img src={image} alt="uploaded image" />
                                <span
                                  className="remove-icon-image"
                                  onClick={() => removeImage(image)}
                                >
                                  &times;
                                </span>
                              </div>
                            </div>
                          ))}
                      <span className="errormsg">
                        <p
                          className="resolution-warning"
                          style={{ color: "red" }}
                        >
                          Recommended resolution: 1080 x 1080 px
                        </p>
                        You need to add at least 3 images. Pay attention to the
                        quality of the pictures you add, comply with the
                        background color standards. Pictures must be in certain
                        dimensions.
                      </span>
                    </div>
                  </div>

                  <div className="form_grpWrp">
                    <label htmlFor="textField">
                      SKU Code <span className="req">*</span>{" "}
                    </label>
                    <div className="form_grp">
                      <input
                        type="text"
                        id="sku_code"
                        name="sku_code"
                        value={formData.sku_code}
                        onChange={handleChange}
                        className="form_ctrl"
                        placeholder="Enter SKU Code"
                      />
                      <span className="errormsg">
                        Enter the SKU Code of the Product
                      </span>
                    </div>
                  </div>
                  {/* Add attributes */}
                  <div className="twnFldW">
                    {attributes.map((attribute) => (
                      <div
                        className={`form_grpWrp ${
                          (attributeValues[attribute.attribute_id]?.length ||
                            0) === 0
                            ? "required"
                            : ""
                        }`}
                        key={attribute.attribute_id}
                      >
                        <label htmlFor={`attribute_${attribute.attribute_id}`}>
                          Add {attribute.attributes}{" "}
                          <span className="req">*</span>
                        </label>
                        <div className="form_grp">
                          <input
                            type="text"
                            id={`attribute_${attribute.attribute_id}`}
                            name={`attribute_${attribute.attribute_id}`}
                            value={
                              attributeformData[attribute.attribute_id] || ""
                            }
                            onChange={(e) =>
                              handleAttributeInputChange(
                                attribute.attribute_id,
                                e.target.value
                              )
                            }
                            onKeyDown={(e) =>
                              handleAttributeKeyDown(attribute.attribute_id, e)
                            }
                            className="form_ctrl"
                            placeholder={`Enter ${attribute.attributes} and press Enter`}
                          />
                        </div>
                        {/* Display Entered Values */}
                        <div className="entered-values">
                          {attributeValues[attribute.attribute_id]?.map(
                            (value, index) => (
                              <div key={index} className="value-tag">
                                {value}
                                <span
                                  className="remove-icon"
                                  onClick={() =>
                                    handleRemoveValue(
                                      attribute.attribute_id,
                                      value
                                    )
                                  }
                                >
                                  &times;
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="productBtnWrp">
                    <button className="BtnB blB">ADD PRODUCT</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
