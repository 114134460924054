import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import logo from "../../../../src/assets/images/logo.png";
import combinedServices from "../../../shared/services/user-service";
import { Spinner } from "reactstrap";
import "./NewCdHeatSheetFormat.scss";
import moment from "moment";

export default function NewDriverHeatSheetFormat() {
  const [driverHeatSheet, setDriverHeatSheet] = useState([]);
  const [date, setDate] = useState("");
  const [classes, setClasses] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false); // New state for tracking PDF download
  const eventId = useParams().id;
  const pdfExportComponent = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await combinedServices.getParticipantWiseHeats({
          eventId: eventId,
        });

        const sortedData = response.data.result.sort(
          (a, b) =>
            a.user_user_firstname.localeCompare(b.user_user_firstname) ||
            a.user_user_lastname.localeCompare(b.user_user_lastname)
        );

        const groupedData = sortedData.reduce((acc, item) => {
          const participantIndex = acc.findIndex(
            (p) =>
              p.participant?.data[0].event_participant_id ===
              item.event_participant_id
          );
          if (participantIndex === -1) {
            acc.push({
              participant: {
                name: `${item.user_user_firstname} ${item.user_user_lastname}`,
                data: [item],
              },
            });
          } else {
            acc[participantIndex].participant.data.push(item);
          }
          return acc;
        }, []);

        const eventDetailsResponse = await combinedServices.getEventDetail(
          eventId
        );
        const time_zone =
          eventDetailsResponse.data.event_time_zone || "Canada/Eastern";
        const final_Date =
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_start,
            time_zone
          ).split(" ")[1] +
          " " +
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_start,
            time_zone
          ).split(" ")[2] +
          "-" +
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_end,
            time_zone
          ).split(" ")[2];

        setDate(final_Date);
        setEventTitle(eventDetailsResponse.data.event_full_title);

        const eventClassesData = await combinedServices.countByClasses(eventId);
        const madeClasses = eventClassesData.data.participants
          .filter((classItem) => classItem.class_status === "MADE")
          .sort((a, b) => new Date(a.run_date) - new Date(b.run_date));

        setClasses(madeClasses);
        console.log("🚀 ~ groupedData.forEach ~ groupedData:", groupedData)

        groupedData.forEach((item) => {
          item.participant.data.forEach((participant) => {
            const findClass = madeClasses.find(
              (cls) => cls.class_id === participant.class_id
            );
            if (findClass) {
              participant.run_date = findClass.run_date;
              participant.order_key = findClass.order_key;
            }
          });

          item.participant.data.sort((a, b) => {
            if (a.heatSheet_round === b.heatSheet_round) {
              return a.heatSheet_event_heat_number - b.heatSheet_event_heat_number;
            }
            return a.heatSheet_round - b.heatSheet_round;
          });
        });

        setDriverHeatSheet(groupedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  useEffect(() => {
    if (!loading && driverHeatSheet.length > 0) {
      setIsDownloading(true); // Start showing spinner
      setTimeout(() => {
        pdfExportComponent.current.save();
      }, 500);

      setTimeout(() => {
        setIsDownloading(false); // Hide spinner after download
        navigate(-1);
      }, 2500);
    }
  }, [driverHeatSheet, loading, navigate]);

  const PDFExportPageTemplate = (props) => (
    <div>
      <span
        style={{
          position: "absolute",
          bottom: "15px",
          right: "30px",
          fontSize: "12px",
          color: "black",
          textAlign: "center",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </span>
    </div>
  );

  const timeaccotolocation2 = (date, timeZone) => {
    return moment.utc(date).tz(timeZone).format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <div className="new-driver-heatsheet">
      {isDownloading && ( // Show spinner while downloading
        <div className="loading-overlay">
          <Spinner style={{ width: "3rem", height: "3rem",position:'absolute',top:'50%',left:'50%' }} />
        </div>
      )}

      <PDFExport
        forcePageBreak=".page-break"
        ref={pdfExportComponent}
        pageTemplate={PDFExportPageTemplate}
        paperSize="Letter"
        margin={{
          top: "0.1in",
          left: "0.4in",
          right: "0.4in",
          bottom: "0.4in",
        }}
        fileName="heatsheet"
      >
        {driverHeatSheet.map((driver, index) => (
          <div key={index} style={{ position: "relative", paddingTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ textAlign: "left" }}>
                <h1 style={{ margin: "0" }}>{eventTitle}</h1>
                <h2 style={{ margin: "0", fontSize: "18px" }}>{date}</h2>
                <h3 style={{ margin: "0", fontSize: "16px" }}>Driver: {driver.participant.name}</h3>
              </div>
              <img
                src={logo}
                alt="Logo"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "2px",
                  height: "80px",
                  width: "80px",
                }}
              />
            </div>

            <table>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Round</th>
                  <th style={{ width: "100px" }}>Heat #</th>
                  <th style={{ width: "300px" }}>Class</th>
                  <th style={{ width: "300px", textAlign: "left" }}>Score</th>
                </tr>
              </thead>
              <tbody>
                {driver.participant.data.map((item, i) => {
                  const isFirstOccurrence =
                    i === 0 ||
                    driver.participant.data[i - 1].heatSheet_round !== item.heatSheet_round;

                  return (
                    <tr key={i}>
                      <td style={{ width: "100px" }}>
                        {isFirstOccurrence ? item.heatSheet_round : ""}
                      </td>
                      <td style={{ width: "100px" }}>{item.heatSheet_event_heat_number}</td>
                      <td style={{ width: "300px" }}>{item.Class_Name}</td>
                      <td
                        style={{
                          width: "300px",
                          borderBottom: "1px solid gray",
                          textAlign: "left",
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  );
                })}
                {index === driverHeatSheet.length - 1 ? <></> : <tr className="page-break"></tr>}
              </tbody>
            </table>
          </div>
        ))}
      </PDFExport>
    </div>
  );
}
