import Header from "../../../Components/clubDashboard/Header/Header";
import combinedServices from "../../../shared/services/user-service";
import React, { useState, useEffect } from "react";
import moment from "moment";
import getLocalData from "../../../shared/localStorage/getData";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";
import { NavLink, Link } from "react-router-dom";
const Profile = () => {
  let navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [clubUserDetails, setUserClubDetail] = useState([]);
  const [isLoder, setIsLoder] = useState(true);
  const [userMobile,setUserMobile] = useState('')
  const profile_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/profile_img.jpg"

  useEffect(async () => {
    var userId = "";

    if (getLocalData("user_info", "isLogin")) {
      userId = getLocalData("user_info", "all").userData.login_user_detail.data
        .user.id;
    }

    var data = {
      user_id: userId,
      clubs: true,
      events: true,
      district: true,
    };

    let profileResponse = await combinedServices.getProfileDetails(data);
    if (profileResponse.status == 401) {
      swal(profileResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    if (profileResponse.status == 200) {
      setIsLoder(false);
      let userDetailData = profileResponse.data;
      setUserDetails(userDetailData);
      setUserClubDetail(userDetailData.club_members);

      //setting the phone number and formatting it
      const mobilePhone = formattedPhone(profileResponse.data.user_mobile);
      
      setUserMobile(mobilePhone)

    }
  }, []);

  const handleEditProfile = async () => {
    setIsLoder(true);
    var tokenResponse = await combinedServices.updateToken();

    if (tokenResponse.status == 200) {
      setIsLoder(false);
    }
  };
  const birthdate = moment(userDetails.user_dob);
  const today = moment();

  const age = today.diff(birthdate, "years");


  const formattedPhone=(phoneNumber)=>{
    if(phoneNumber==undefined){
      return ''
    }
    const pattern = /^\(\d{3}\)\d{3}-\d{4}$/; // Define a regular expression pattern for the desired format
    if(pattern.test(phoneNumber)){
      return phoneNumber
    }else{
      // Remove any non-numeric characters from the phone number
      const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
      
      // Format the numeric phone number as (xxx)xxx-xxxx
      return numericPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }
    }


  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid dbInner">
          <div className="">
            <div className="dbMainHd">
              My Profile
              <span className="icon">
                <a href="/club-setting">
                  <span className="material-icons p-2">settings</span>
                </a>
              </span>
            </div>
            {isLoder ? (
              <div className="text-center mt-4 loadMore">
                <Spinner color="primary" />
              </div>
            ) : (
              <ul className="flexBox profileDet">
                <li>
                  <div className="">
                    <div className="flexBox userInfo">
                      <div className="left">
                        <img src={profile_img} alt="" />
                        <a className="editLink">
                          <span className="material-icons">edit</span>
                        </a>
                      </div>
                      <div className="right">
                        <NavLink to="/update-profile">
                          <a
                            href="#"
                            className="editBtn"
                            style={{ marginRight: "10px" }}
                            onClick={handleEditProfile}
                          >
                            Edit
                          </a>
                        </NavLink>
                        <br />
                        Name: {userDetails.user_firstname}{" "}
                        {userDetails.user_lastname}
                        <br />
                        Age: {age}
                        <br />
                        Location: {`${userDetails.user_city} ${userDetails.user_state}`} <br />
                        Years Racing:{" "}
                        {moment(userDetails.user_createdon).format("YYYY")}
                        <br />
                        {/* Member Since: {moment(userDetails.user_createdon).format("ddd , MMM D , YYYY")}<br /> */}
                        Phone: {userDetails.user_areacodemobile}-
                        {userMobile}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="">
                    <div className="profileHd">Club Members</div>
                    <ul className="clubMemebrList">
                      {clubUserDetails.map((club) => {
                        return (
                          <li>
                            <span className="left">{club.club.club_name}</span>
                            <span className="right">
                              since{" "}
                              {moment(club.club.club_foundedon).format("YYYY")}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>

                {userDetails.event_participants > 0 && (
                  <li className="full">
                    <div className="">
                      <div className="profileHd">Races Attended</div>
                      <ul className="raceAttended">
                        {userDetails.event_participants.map((raceDetail) => {
                          return (
                            <li>
                              <div className="left">
                                {raceDetail.event.full_title}
                              </div>
                              <div className="right">
                                Charleston Model Boaters
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
