import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SuccessPage.scss";
import combinedServices from "../../../shared/services/user-service";
import CryptoJS from "crypto-js";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import userConstants from "../../../shared/constants/user-constants";

const CancelledPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const capturePayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      const encryptedOrderId = localStorage.getItem("temp_orderId");
      const secretKey = userConstants.CRYPTOSECRETKEY;

      const decryptedOrderId = CryptoJS.AES.decrypt(
        encryptedOrderId,
        secretKey
      ).toString(CryptoJS.enc.Utf8);

      console.log("Decrypted Order ID:", decryptedOrderId);
      localStorage.removeItem("temp_orderId");

      if (!token) {
        console.error("Missing token.");
        return;
      }

      try {
        const response = await combinedServices.captureCancelpayment(
          token,
          decryptedOrderId
        );
        if (response && response.data) {
          console.log(
            "Payment cancellation logged successfully:",
            response.data
          );
        } else {
          console.error("No cancellation details found.");
        }
      } catch (error) {
        console.error("Error capturing cancellation:", error);
      }
    };

    capturePayment();
  }, [location.search]);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleTryAgain = () => {
    navigate("/addTocart");
  };

  return (
    <>
      <Header />
      <div className="success-page cancelled-page">
        <div className="success-container">
          <div className="icon-container">
            <span className="check-icon failed">✘</span>
          </div>
          <h1>Payment Cancelled</h1>
          <p>
            Your payment process was not completed. You can try again or return
            to the home page.
          </p>
          <div className="button-group">
            <button className="btn btn-primary" onClick={handleTryAgain}>
              Try Again
            </button>
            <button className="btn btn-secondary" onClick={handleGoHome}>
              Go to Home
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CancelledPage;
