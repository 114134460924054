import React, { useEffect } from "react";
import styled from "styled-components";
import "./RaceInterface.scss";
import Header from "../../../Components/clubDashboard/Header/Header";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./column";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import moment from "moment-timezone";
import swal from "sweetalert";
import Modal from "react-modal";
 
import getRemoveData from "../../../shared/localStorage/removeData";
 
import { NavLink, Link } from "react-router-dom";
import { Chip } from "@mui/material";
 
//moment.tz.setDefault("Asia/Kolkata");
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
moment.tz.setDefault("timeZone");
// import './styles.css'
 
const Container = styled.div`
  display: flex;
`;
 
export default function Scheduler() {
  const [tasks, setTasks] = React.useState();
  const [columns, setColumns] = React.useState();
  const [columnOrder, setColumnOrder] = React.useState();
  const params = useParams();
  const [event_id, setEvent_id] = React.useState(params.id);
  const [eventdetails, setEventdetails] = React.useState({});
  const [classData, setClassData] = React.useState({});
  const [classData1, setClassData1] = React.useState({});
  const [classData2, setClassData2] = React.useState({});
  const [dateArray, setDateArray] = React.useState();
  const [initialData, setInitialData] = React.useState();
  const [generateheat, setGenerateheat] = React.useState(true);
  const [showHeatButton, setShowHeatButton] = React.useState(false);
  const [minClass, setMinClass] = React.useState([]);
  const [isLoder, setIsLoder] = React.useState(true);
  const [cancelledClass, setCancelledClass] = React.useState([]);
  const [cancelledClassMsg, setCancelledClassMsg] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [timezone1, setTimezone1] = React.useState("Canada/Eastern")



  useEffect(async () => {
    initialData2()
  }, []);
  async function initialData2() {
    let cancelNonQualifyingClassResponse =
      await combinedServices.cancelNonQualifyingClassOnClick({ event_id: event_id });
    await combinedServices
      .getClassWithMinimumEntry({ event_id: parseInt(event_id) })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            window.location.href = "/login";
          });
        }
        setMinClass(res.data);
        setCancelledClass(res.data);
        setCancelledClassMsg(res.msg)
        setIsLoder(false)
      })
      .catch((err) => {
        setIsLoder(false)

      });
    await combinedServices
      .cancelClassWithZeroEntry({ event_id: parseInt(event_id) })
      .then((res) => { })
      .catch((err) => { });
    var eventClassesData = await combinedServices.countByClasses(event_id);
    var newArr = [];
    if (!eventClassesData?.data?.event?.cancelled_classes) {
      let cancelNonQualifyingClassResponse =
        await combinedServices.cancelNonQualifyingClass({ event_id: event_id });

      if (cancelNonQualifyingClassResponse.status == 200) {
        setIsOpen(!isOpen)

        // setCancelledClass(cancelNonQualifyingClassResponse.data);
        // setCancelledClassMsg(cancelNonQualifyingClassResponse.msg)
        // this.getAllClassDetails(event_id);
      }
    }
    eventClassesData?.data?.participants?.forEach((item, index) => {
      if (item.class_status == "MADE" && item.run_date == null) {
        newArr.push(index);
      }
    });
    if (newArr.length == 0) {
      setShowHeatButton(true);
    } else {
      setShowHeatButton(false);
    }
    setEventdetails(eventClassesData?.data?.event);
    setTimezone1(eventClassesData?.data?.event.time_zone)
    setClassData(eventClassesData?.data.participants);
    setClassData1(eventClassesData.data.participants);
    setDateArray(eventClassesData.data.event.event_start);
    const timeaccotolocation3 = (x) => {
      if (x == null) { return null }
      else {
        const utcMoment = moment.utc(x);
        const localMoment = utcMoment.tz(eventClassesData?.data?.event.time_zone == '' ? 'Canada/Eastern' : eventClassesData?.data?.event.time_zone);

        // const timeZoneAbbreviation = localMoment.format('z');
        const timeZoneOffset = localMoment.format("Z");

        return localMoment.format("ddd MMM DD YYYY");
      }
    };
    const eventStartDate = new Date(timeaccotolocation3(eventClassesData.data.event.event_start));
    const eventEndDate = new Date(timeaccotolocation3(eventClassesData.data.event.event_end));

    const calculateDaysDifference = (date1, date2) => {
      const oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs((date1 - date2) / oneDay));
    };

    const daysDifference = calculateDaysDifference(
      eventStartDate,
      eventEndDate
    );

    const columnIds = Array.from(
      { length: daysDifference + 2 },
      (_, index) => `column-${index + 1}`
    );


    const tasksData1 = eventClassesData.data.participants.map((item, index) =>
    ({

      id: `task-${item.event_class_id}`,
      content: item.class_name,
      order: item.order_key,
      run_date: (item.run_date),
      status: item.class_status,
    }));
    const tasksData = eventClassesData.data.participants.map((item, index) =>
    ({

      id: `task-${item.event_class_id}`,
      content: item.class_name,
      order: item.order_key,
      run_date: (item.run_date),
      status: item.class_status,
      confirmed_participants: item.confirmed_participants //new line
    }));
    const notmadeclasses = tasksData.filter((x) => x.status === "NOT_MADE");
    setMinClass(notmadeclasses)
    const formattedTasks = {};


    tasksData1.forEach((task, index) => {
      formattedTasks[task.id] = {
        id: task.id,
        content: task.content,
        status: task.status
      };
    });

    const columns = {};

    // Ensure column-1 has tasks with null run_date
    const nullRunDateTasks = tasksData1
      .filter((task) => task.run_date === null)
      .map((task) => task.id);

    columns["column-1"] = {
      id: "column-1",
      title: "Column 1",
      runDate: null,
      taskIds: nullRunDateTasks,
    };

    // Filter tasks for each subsequent column based on run_date
    for (let i = 2; i <= daysDifference + 2; i++) {
      const currentDate = (eventStartDate);
      const adjustedDate = new Date(currentDate);
      adjustedDate.setDate(adjustedDate.getDate() + (i - 2));
      const formattedDate = adjustedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      // currentDate.setDate(currentDate.getDate() + (i - 2)); // Adjusted date calculation
      // const formattedDate = new Date(currentDate).toLocaleDateString('en-US', {
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit',
      // }); // Use date part only for comparison

      const taskIds = tasksData1
        .sort((a, b) => a.order - b.order)
        .filter((task) => {
          if (task.run_date === null && i === 1) {
            return true;
          } else if (task.run_date) {
            const runDate = timeaccotolocation3(task.run_date)
            // {}
            const runDate1 = new Date(runDate); // Convert the string to a Date object
            const nextDate = new Date(runDate1);
            // nextDate.setDate(runDate1.getDate() + 1);
            const formattedDate1 = nextDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });;
            return formattedDate1 === formattedDate;
          }
          return false;
        })
        .map((task) => task.id);

      columns[`column-${i}`] = {
        id: `column-${i}`,
        title: `Column ${i}`,
        runDate: formattedDate,
        taskIds: taskIds,
      };
    }
    const initialData = {
      formattedTasks,
      columns,
      columnOrder: columnIds,
    };

    setInitialData(initialData);
    setTasks(initialData.formattedTasks);
    setColumns(initialData.columns);
    setColumnOrder(initialData.columnOrder);
  }


  function compareOrderKeys(classarray1, classarray2) {
    if (classarray1 && classarray2) {
      if (classarray1.length !== classarray2.length) {
        return false;
      }
      for (let i = 0; i < classarray1.length; i++) {
        if (classarray1[i].order_key !== classarray2[i].order_key) {
          return false;
        }
      }
 
      return true;
    }
 
    return false;
  }
  function convertToUTC(dateTimeStr) {
    const parsedDate = moment(dateTimeStr, "MM/DD/YYYY");
    const formattedDate = parsedDate.format("MMM DD YYYY");
    // const datePart = dateTimeStr.split(' ')[1] + ' ' + dateTimeStr.split(' ')[2] + ' ' + dateTimeStr.split(' ')[3];
    const dateOnly = moment.tz(formattedDate, 'MMM DD YYYY', timezone1).startOf('day');

    const utcDate = moment.utc(dateOnly);

    const timeZone = utcDate.format('z');

    return utcDate.toISOString();
  }
  function convertToUTCclose(dateTimeStr) {
    const parsedDate = moment(dateTimeStr, "MM/DD/YYYY");
    const formattedDate = parsedDate.format("MMM DD YYYY");
    // const datePart = dateTimeStr.split(' ')[1] + ' ' + dateTimeStr.split(' ')[2] + ' ' + dateTimeStr.split(' ')[3];

    const dateOnly = moment.tz(formattedDate, 'MMM DD YYYY', timezone1).set({ hour: 23, minute: 59, second: 1 });

    const utcDate = moment.utc(dateOnly);

    return utcDate.toISOString();
  }
  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
 
    if (!destination) {
      return;
    }
 
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
 
    if (type === "column") {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      setColumnOrder(newColumnOrder);
      return;
    }
 
    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];
 
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
 
      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
 
      setColumns((lastColumns) => ({
        ...lastColumns,
        [newColumn.id]: newColumn,
      }));


      for (let i = 0; i < newTaskIds.length; i++) {
        const taskId = newTaskIds[i];
        const eventId = parseInt(taskId.split("-")[1]);
        const orderKey = i + 1;
        const keys = Object.keys(columns);
        const lastKey = keys[keys.length - 1];
        const lastObject = columns[lastKey];

        try {
          if (lastObject.id == finish.id) {
            const data = {
              event_class_ids: [eventId],
              run_date:
                convertToUTC(String(finish.runDate)),
              order_key: orderKey,
            };
            let eventClassesData = await combinedServices.assignDateForEvent(
              data
            );
          }
          else {
            const data = {
              event_class_ids: [eventId],
              run_date:
                convertToUTC(String(finish.runDate)),
              order_key: orderKey,
            };
            let eventClassesData = await combinedServices.assignDateForEvent(
              data
            );
          }

        } catch (error) { }
      }
      let eventClassesData1 = await combinedServices.countByClasses(event_id);
      var newArr = [];
      eventClassesData1.data.participants.forEach((item, index) => {
        if (item.class_status == "MADE" && item.run_date == null) {
          newArr.push(index);
        }
      });
      if (newArr.length == 0) {
        setShowHeatButton(true);
      } else {
        setShowHeatButton(false);
      }
      setClassData2(eventClassesData1.data.participants);
      setClassData(eventClassesData1.data.participants);
      setGenerateheat(compareOrderKeys(classData1, classData2));
      return;
    }
 
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };
 
    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };
 
    setColumns((lastColumns) => ({
      ...lastColumns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    }));
    const keys = Object.keys(columns);
    const lastKey = keys[keys.length - 1];
    const lastObject = columns[lastKey];
    for (let i = 0; i < newFinish.taskIds.length; i++) {
      if (newFinish.id === "column-1") {
        const taskId = newFinish.taskIds[i];
        const eventId = parseInt(taskId.split("-")[1]);
        const orderKey = i + 1;
        const data = {
          event_class_ids: [eventId],
          run_date: null,
          order_key: orderKey,
        };
        try {
          let eventClassesData = await combinedServices.assignDateForEvent(
            data
          );
        } catch (error) { }
      } else {
        if (lastObject.id === newFinish.id) {
          const taskId = newFinish.taskIds[i];
          const eventId = parseInt(taskId.split("-")[1]);
          const orderKey = i + 1;
          const data = {
            event_class_ids: [eventId],
            run_date:
              convertToUTCclose(String(newFinish.runDate)),
            order_key: orderKey,
          };
          try {
            let eventClassesData = await combinedServices.assignDateForEvent(
              data
            );
          } catch (error) { }

        }
        else {
          const taskId = newFinish.taskIds[i];
          const eventId = parseInt(taskId.split("-")[1]);
          const orderKey = i + 1;
          const data = {
            event_class_ids: [eventId],
            run_date:
              convertToUTC(String(newFinish.runDate)),
            order_key: orderKey,
          };
          try {
            let eventClassesData = await combinedServices.assignDateForEvent(
              data
            );
          } catch (error) { }
        }
      }
    }
    let eventClassesData1 = await combinedServices.countByClasses(event_id);
    var newArr = [];
    eventClassesData1.data.participants.forEach((item, index) => {
      if (item.class_status == "MADE" && item.run_date == null) {
        newArr.push(index);
      }
    });
    if (newArr.length == 0) {
      setShowHeatButton(true);
    } else {
      setShowHeatButton(false);
    }
 
    setClassData2(eventClassesData1.data.participants);
    setClassData(eventClassesData1.data.participants);
    setGenerateheat(compareOrderKeys(classData1, classData2));
  };
  const calculateDaysBetweenTwoDate = (startDate, endDate) => {
    var future = moment(endDate);
    var start = moment(startDate);
    var d = future.diff(start, "days") + 1; // 9
 
    //var d = Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));
 
    return d;
  };
  const handleSchedule = () => {

    if (cancelledClass.length > 0) {
      setOpen(true)
    } else {
      window.location.href = `../manage-heatsheet/${event_id}?regenrate=${generateheat}`;

    }
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleModalMin = () => {
    setOpen(!open);
  };
  const handleYes = async () => {
    const data = {
      event_id,
    };
    await combinedServices.allowClassWithMinimumEntry(data).then((res) => {
      toggleModalMin();
      swal(
        "Done!",
        "You can schedule classes with minimum entries now",
        "success"
      );
      // this.componentDidMount();
      initialData2()
    });
  };
  const handleNo = () => {
    window.location.href = `../manage-heatsheet/${event_id}?regenrate=${generateheat}`;
  };
  const timeaccotolocation2 = (x, y) => {
    if (y == null) {
      return null
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(y);

    const timeZoneOffset = localMoment.format("Z");


    return localMoment.format("ddd, MMM DD YYYY");

  };
  return (
    <>
      <div>
        <Header />
        <div className="dbRight">
          <div className="dbMid dbInner">
            <div className="flexBox spacebetween topHdBox">
              <div className="left">
                <div className="dbMainHd">Race Interface</div>
                <div className="text flexBox">
                  <div className="eventDate">
                    Event : Start -{" "}
                    {(timeaccotolocation2(eventdetails?.event_start, eventdetails?.time_zone == '' ? 'Canada/Eastern' : eventdetails?.time_zone))
                    }{" "}
                    - End -{" "}
                    {(timeaccotolocation2(eventdetails?.event_end, eventdetails?.time_zone == '' ? 'Canada/Eastern' : eventdetails?.time_zone))}
                  </div>
                  <div className="eventTime">
                    Duration :{" "}
                    {calculateDaysBetweenTwoDate(
                      eventdetails.event_start,
                      eventdetails.event_end
                    ) > 0
                      ? calculateDaysBetweenTwoDate(
                        eventdetails.event_start,
                        eventdetails.event_end
                      ) + " days"
                      : ""}{" "}
                  </div>
                </div>
                <div
                  className="bradcrumb"
                  style={{ backgroundColor: "#1c60a6" }}
                >
                  <a href="/">Home</a>
                  <a href="/event-management">Event Management</a>
                </div>
              </div>
              <div className="right tar">
                {showHeatButton ? (
                  <a className="blueBtn ml10" onClick={() => handleSchedule()}>
                    Heats
                  </a>
                ) : (
                  ""
                )}
 
                <NavLink
                  className="blueBtn ml10"
                  to={"/payment-details/" + event_id}
                >
                  Payment Details
                </NavLink>
              </div>
            </div>
            {cancelledClass?.length > 0 ? (
              <>
                <Modal
                  style={{ width: "300px" }}
                  isOpen={isOpen}
                  //onRequestClose={toggleModal}
                  className="mymodal"
                  contentLabel="My dialog"
                >
                  <div className="cancelledClassMsg">
                    {cancelledClassMsg}
                  </div>
                  <ol>
                    {cancelledClass?.map((value) => {
                      return <li>{value}</li>;
                    })}{" "}
                  </ol>
                  <button
                    className="cancelledPopup"
                    onClick={toggleModal}
                  >
                    Close modal
                  </button>
                </Modal>
              </>
            ) : ""}

            <Modal
              style={{ width: "300px" }}
              isOpen={open}
              className="mymodal"
              contentLabel="My dialog"
            >
              <div className="cancelledClassMsg">
                Confirm to generate the heats
              </div>
              {/* <ol>
                {cancelledClass?.map((value) => {
                  return <li>{value}</li>;
                })}{" "}
              </ol> */}
              <button
                className="cancelledPopup"
                onClick={toggleModalMin}
              >
                Back
              </button>
              {/* <button className="yesPopup" onClick={handleYes}>
                YES
              </button> */}
              <button className="noPopup" onClick={handleNo}>
                confirm
              </button>
            </Modal>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="all-columns"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <Container
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >

                    {columnOrder?.map((columnId, index) => {
                      const column = columns[columnId];

                      const columnTasks = column?.taskIds?.map(
                        (taskId) => tasks[taskId]
                      );

                      return (

                        <Column
                          key={column.id}
                          column={column}
                          tasks={columnTasks}
                          index={index}
                          data={classData}
                          eventdata={eventdetails}
                        />

                      );
                    })}
                    {provided.placeholder}
                  </Container>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </>
  );
}
 