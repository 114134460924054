import React, { useEffect, useState } from "react";
import combinedServices from "../../../shared/services/user-service";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";
import "./RaceAdministratorList.scss";

function RaceAdministratorList({
  raceAdministratorList,
  district_id,
  showAdminsResponsive,
  setShowAdminResponsive,
}) {
  console.log("🚀 ~ RaceAdministratorList ~ district_id:", district_id);
  const [searchUser, setSearchUser] = useState("");
  const [inputbox, setInputbox] = useState(false);
  const [subMember, setSubMember] = useState([]);
  const [raceAdmins, setRaceAdmins] = useState(raceAdministratorList);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let eventSubMember =
          await combinedServices.getAllUserForManageEntries();
        if (eventSubMember.status === 401) {
          swal(eventSubMember.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        if (eventSubMember.status === 200) {
          const userList = eventSubMember.data
            .filter((user) => user.user_status === "active")
            .map((user) => ({
              name: `${user.user_firstname} ${user.user_lastname}`,
              id: user.id,
            }));
          setSubMember(userList);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteRaceAdministrator = async (user_id, district_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this administrator!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await combinedServices.removeRaceAdministrator({
            user_id,
            district_id,
          });
          if (res.status === 200) {
            swal(
              "Deleted!",
              "Race Administrator removed successfully.",
              "success"
            );
            setRaceAdmins(
              raceAdmins.filter((admin) => admin.user.id !== user_id)
            );
          } else {
            swal("Error", res.msg || "Failed to remove administrator", "error");
          }
        } catch (error) {
          console.error("Error removing race administrator:", error);
          swal("Error", "Something went wrong. Please try again.", "error");
        }
      }
    });
  };

  const handleSearchBox = (e) => {
    setSearchUser(e.target.value);
    setInputbox(e.target.value !== "");
  };

  const handleSubUser = async (user) => {
    setInputbox(false);
    document.getElementById("searchUser").value = user.name;


    try {
      const response = await combinedServices.addRaceAdministrator({
        user_id: user.id,
        district_id: district_id,
      });

      if (response.status === 200) {
        swal("Success", "Race Administrator added successfully!", "success");
        setRaceAdmins([
          ...raceAdmins,
          {
            user: {
              id: user.id,
              user_firstname: user.name.split(" ")[0],
              user_lastname: user.name.split(" ")[1],
            },
          },
        ]);
      } else {
        swal("Error", response.msg || "Failed to add administrator", "error");
      }
    } catch (error) {
      console.error("Error adding race administrator:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <div className={`myRace raceadministrator ${showAdminsResponsive}`}>
      <div className="userName member">
        <div className="myRaceHd" style={{ padding: "20px 0" }}>
          Race Administrators
          <span
            onClick={() => {
              setShowAdminResponsive("");
            }}
            className="nav-control closeBtn"
          >
            <span class="material-icons">close</span>
          </span>
        </div>
        <div className="flexBox nowrap itemCenter districtSelectBox">
          <div className="eventInner searchBox membersList-box">
            <input
              type="text"
              className="searchUser"
              id="searchUser"
              placeholder="search user"
              onChange={handleSearchBox}
              autoComplete="off"
            />
            {inputbox && (
              <ul className="flexBox eventForm" id="toggleText">
                {subMember
                  .filter(
                    (val) =>
                      searchUser === "" ||
                      val.name.toLowerCase().includes(searchUser.toLowerCase())
                  )
                  .map((user) => (
                    <li
                      key={user.id}
                      onClick={() => {
                        handleSubUser(user);
                      }}
                    >
                      {user.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
        <React.Fragment>
          <ul className="memberList" style={{ display: "contents" }}>
            {raceAdmins.length > 0 ? (
              raceAdmins.map((item) => (
                <li key={item.user.id}>
                  <a className="flexBox nowrap itemCenter spacebetween">
                    <span className="name">
                      {`${item.user.user_firstname} ${item.user.user_lastname}`}
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="count me-2">{item.user.id}</span>
                      <button className="entriesButton">
                        <span
                          className="material-icons-outlined"
                          onClick={() =>
                            handleDeleteRaceAdministrator(
                              item.user.id,
                              district_id
                            )
                          }
                        >
                          delete
                        </span>
                      </button>
                    </div>
                  </a>
                </li>
              ))
            ) : (
              <span>No Race Administrators</span>
            )}
          </ul>
        </React.Fragment>
      </div>
    </div>
  );
}

export default RaceAdministratorList;
