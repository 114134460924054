import React, { useEffect } from "react";
import iconSvg from "../../assets/images/social_icon_image.svg";
import Header from "../../Components/Layout/Header/Header";
import combinedServices from "../../shared/services/user-service";
import { useNavigate } from "react-router-dom";

function Store() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStoreStatus = async () => {
      try {
        const response = await combinedServices.storeStatus();
        const storeData = response?.data?.data?.data?.[0];
        if (storeData) {
          if (storeData.isAvailable) {
            navigate("/productListing");
          }
        } else {
          console.error("No store data found");
        }
      } catch (error) {
        console.error("Error fetching Store status:", error);
        navigate("/");
      }
    };
    fetchStoreStatus();
  }, []);
  return (
    <div>
      <Header />
      <div class="nam_bann">
        <div class="nam_bann_inner">
          <h2>COMING SOON</h2>
          <p>ARE YOU READY</p>
          <div class="socialIcWrp">
            {/* <!-- <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a> --> */}
            <img src={iconSvg} alt="social icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
