import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useEffect, useState, useRef } from "react";
import combinedServices from "../../../shared/services/user-service";
import logo from "../../../../src/assets/images/logo.png";
import "./NewCdHeatSheetFormat.scss";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

export default function NewCdHeatSheetFormat() {
  const [heatSheet, setHeatSheet] = useState([]);
  const [evenTitle, setEventTitle] = useState("");
  const [date, setDate] = useState("");
  const eventId = useParams().id;
  const pdfExportComponent = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await combinedServices.getAllHeatSheet({
          event_id: eventId,
        });
        const sortedData = response.data.sort(
          (a, b) => a.heat_number - b.heat_number
        );
        setHeatSheet(sortedData);

        const eventDetailsResponse = await combinedServices.getEventDetail(
          eventId
        );
        const time_zone = eventDetailsResponse.data.event_time_zone;
        const final_Date =
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_start,
            time_zone
          ).split(" ")[1] +
          " " +
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_start,
            time_zone
          ).split(" ")[2] +
          "-" +
          timeaccotolocation2(
            eventDetailsResponse.data.event_event_end,
            time_zone
          ).split(" ")[2];
        setDate(final_Date);
        setEventTitle(eventDetailsResponse.data.event_full_title);

        // Auto-download PDF after fetching data
        setTimeout(() => {
          if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
            setTimeout(() => navigate(-1), 1000); // Navigate back after 1 second
          }
        }, 1000);
      } catch (err) {
        console.log("Error fetching heat sheet: ", err);
      }
    }

    fetchData();
  }, [eventId, navigate]);

  const PDFExportPageTemplate = (props) => (
    <div>
      {/* Logo */}
      <img
        src={logo}
        alt="Logo"
        style={{
          position: "absolute",
          top: "20px",
          right: "30px",
          height: "100px",
          width: "100px",
        }}
      />

      {/* Title */}
      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
          margin: 0,
        }}
      >
        <span
          style={{
            textAlign: "center",
            color: "gray",
            fontWeight: "600",
          }}
        >
          {evenTitle}, {date}
        </span>
      </div>

      {/* Page Number */}
      <span
        style={{
          position: "absolute",
          bottom: "15px",
          right: "30px",
          fontSize: "12px",
          color: "black",
          textAlign: "center",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </span>
    </div>
  );

  const timeaccotolocation2 = (date, timeZone) => {
    if (!timeZone) {
      timeZone = "Canada/Eastern";
    }
    return moment.utc(date).tz(timeZone).format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <div className="new-cd-heatsheet">
      <PDFExport
        forcePageBreak=".page-break"
        ref={pdfExportComponent}
        pageTemplate={PDFExportPageTemplate}
        paperSize="Letter"
                margin={{
          top: "2in",
          left: "0.4in",
          right: "0.4in",
          bottom: "0.4in",
        }}
        fileName="heatsheet"
      >
        <table className="table-1">
          <tr>
            <th className="col-1">Name</th>
            <th className="col-2">Class</th>
            <th className="col-3">Score</th>
          </tr>
        </table>
        <table className="table-2">
          {heatSheet.map((heat) => (
            <React.Fragment key={heat.heat_number}>
              <tr>
                <th className="col-1" style={{ borderRight: "1px solid black" }}>
                  Heat {heat.heat_number}
                </th>
                <th className="col-2" style={{ borderRight: "1px solid black" }}>
                  {heat.className}
                </th>
                <th className="col-3">&nbsp;</th>
              </tr>
              {heat.participants.map((p, index) => (
                <tr key={index}>
                  <td style={{ borderRight: "1px solid black" }}>
                    {p.event_participant_class.event_participant.user.user_firstname}{" "}
                    {p.event_participant_class.event_participant.user.user_lastname}
                  </td>
                  <td style={{ borderRight: "1px solid black" }}></td>
                  <td></td>
                </tr>
              ))}

              <tr>
                <td
                  colSpan="1"
                  style={{ height: "20px", borderRight: "1px solid black" }}
                ></td>
                <td
                  colSpan="1"
                  style={{ height: "20px", borderRight: "1px solid black" }}
                ></td>
                <td colSpan="1" style={{ height: "20px" }}></td>
              </tr>
            </React.Fragment>
          ))}
        </table>
      </PDFExport>
    </div>
  );
}
