import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import pencilIcon from "../../../assets/images/pencil.svg";
import deleteIcon from "../../../assets/images/bin.svg";
import calenderIcon from "../../../assets/images/calendar_2693507.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import combinedServices from "../../../shared/services/user-service";

const ProductList = () => {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [attributesNames, setAttributesNames] = useState({});
  const [storeVisiblity, setStoreVisiblity] = useState("");
  const [dateresponse, setDateResponse] = useState(false);
  const [skuSort, setSkuSort] = useState("");
  const [nameSort, setNameSort] = useState("");
  const [expandedAttributes, setExpandedAttributes] = useState({});

  const totalPages = Math.ceil(totalProducts / limit);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let productsData = await combinedServices.getProducts(
          page,
          limit,
          searchQuery,
          skuSort,
          nameSort
        );
        if (productsData && productsData.data) {
          setProductsData(productsData.data.products || []);
          setTotalProducts(productsData.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };

    const fetchStoreStatus = async () => {
      try {
        const response = await combinedServices.adminstoreStatus();
        setStoreVisiblity(response.data?.data?.data[0]);
      } catch (error) {
        console.error("Error fetching Store status:", error);
      }
    };
    fetchProducts();
    fetchStoreStatus();
  }, [
    page,
    limit,
    searchQuery,
    deleteTrigger,
    dateresponse,
    skuSort,
    nameSort
  ]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await combinedServices.getallAttribute();
        if (response && response.data) {
          const names = response.data.existingCatagory
            .map((attribute) => {
              return {
                [attribute.attribute_id]: attribute.attributes
              };
            })
            .reduce((acc, curr) => Object.assign(acc, curr), {});

          setAttributesNames(names);
        } else {
          console.error("Unexpected response format for attributes:", response);
        }
      } catch (error) {
        console.error("Error fetching attribute names:", error);
      }
    };
    fetchAttributes();
  }, []);

  const handleButtonClick = () => {
    navigate("/add-product");
  };

  const handleButtonClickAddEvent = () => {
    navigate("/AddEvent");
  };

  const handleEditProduct = (productId) => {
    navigate(`/edit-Product/${productId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const deleteCatagory = async (product_id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const response = await combinedServices.deleteProduct(product_id);
        if (response.status === 200) {
          Swal.fire(
            "Deleted!",
            response.data.message || "The Product has been deleted.",
            "success"
          );
          setDeleteTrigger((prev) => !prev);
        } else {
          Swal.fire(
            "Warning!",
            "The Product may have been deleted, but an unexpected response was received.",
            "warning"
          );
        }
      }
    } catch (error) {
      console.error("Error deleting Product:", error);
      Swal.fire("Error!", "Failed to delete the Product.", "error");
    }
  };

  const toggleProductStatus = async (id) => {
    try {
      const response = await combinedServices.changeproductStatus({
        product_id: id
      });
      if (response.status === 200) {
        setDeleteTrigger(response.data.message);
        Swal.fire(
          "Changed!",
          response.data.message || "The Status has been Updated.",
          "success"
        );
      } else {
        Swal.fire(
          "Warning!",
          "The Product status may have been Changes, but an unexpected response was received.",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire("Error!", "Failed to change status of Product.", "error");
    }
  };

  const changeStoreStatus = async () => {
    try {
      const response = await combinedServices.StoreStausChange();
      setStoreVisiblity(response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching Store status:", error);
    }
  };

  const addDates = async (product_id, fromDate, toDate) => {
    try {
      const { value: formValues } = await Swal.fire({
        title: "Enter Date Range",
        html: `<div style="display: flex; justify-content: center; gap: 20px; width: 100%; padding: 0 20px;">
            <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
              <label for="swal-input1" style="margin-bottom: -10px; font-weight: bold;">From Date</label>
              <input id="swal-input1" class="swal2-input" placeholder="From Date" type="date" style="width: 100%; max-width: 200px;" ${
                fromDate ? `value="${fromDate}"` : ""
              }>
            </div>
            <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
              <label for="swal-input2" style="margin-bottom: -10px; font-weight: bold;">To Date</label>
              <input id="swal-input2" class="swal2-input" placeholder="To Date" type="date" style="width: 100%; max-width: 200px;" ${
                toDate ? `value="${toDate}"` : ""
              }>
            </div>
          </div>`,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save",
        width: "700px", // Increase the width of the modal
        padding: "2em", // Add padding to the modal
        backdrop: true, // Add a backdrop for better focus
        customClass: {
          container: "custom-swal-container",
          popup: "custom-swal-popup",
          input: "custom-swal-input"
        },
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value
          ];
        }
      });

      if (formValues) {
        const [fromDate, toDate] = formValues;
        if (!fromDate || !toDate) {
          Swal.fire("Error!", "Please enter both From and To dates.", "error");
          return;
        }
        const fromDateUTC = new Date(`${fromDate}T00:00:00Z`).toISOString();
        const toDateUTC = new Date(`${toDate}T23:59:59Z`).toISOString();
        console.log("asd", fromDateUTC, toDateUTC);

        const response = await combinedServices.UpdateDates({
          productId: product_id,
          fromDate: fromDateUTC,
          toDate: toDateUTC
        });

        setDateResponse(!dateresponse);
        if (response.status === 200) {
          Swal.fire(
            "Saved!",
            response.data.message || "Date range has been saved.",
            "success"
          );
          // Trigger any state update or refresh logic here
        } else {
          Swal.fire(
            "Warning!",
            "An unexpected response was received while saving the date range.",
            "warning"
          );
        }
      }
    } catch (error) {
      console.error("Error saving date range:", error);
      Swal.fire("Error!", "Failed to save the date range.", "error");
    }
  };

  const handleSkuSort = () => {
    setNameSort("");
    setSkuSort((prev) => {
      if (prev === null || prev === "des") return "acc";
      return "des";
    });
  };

  const handlenameSort = () => {
    setSkuSort("");
    setNameSort((prev) => {
      if (prev === null || prev === "des") return "acc";
      return "des";
    });
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Product Managment</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product Name and SKU Code"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="filBtnWrp">
              <div className="toggleContainer">
                <span className="toggleHeading">
                  <strong>STORE STATUS - </strong>
                </span>{" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={storeVisiblity.isAvailable}
                    onChange={() => changeStoreStatus()}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <br></br>
              <button className="nambaBtn" onClick={handleButtonClick}>
                + Add New Product
              </button>
              <button className="nambaBtn" onClick={handleButtonClickAddEvent}>
                + Add Event
              </button>
            </div>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th
                      className="sortable"
                      onClick={handlenameSort}
                      style={{ cursor: "pointer" }}
                    >
                      Product Name
                      <span className="arrow">
                        {nameSort === "acc"
                          ? "↑"
                          : nameSort === "des"
                          ? "↓"
                          : "↕"}
                      </span>
                    </th>
                    <th>Attributes</th>
                    <th>Product Type</th>
                    <th
                      className="sortable"
                      onClick={handleSkuSort}
                      style={{ cursor: "pointer" }}
                    >
                      SKU Code
                      <span className="arrow">
                        {skuSort === "acc"
                          ? "↑"
                          : skuSort === "des"
                          ? "↓"
                          : "↕"}
                      </span>
                    </th>
                    <th>Total QTY.</th>
                    <th>Retail Price</th>
                    <th>Cost Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productsData.length > 0 ? (
                    productsData.map((product, index) => {
                      // Parse the images and attributes
                      let imageUrls = [];
                      try {
                        imageUrls = JSON.parse(product.images || "[]");
                      } catch (error) {
                        console.error("Failed to parse images:", error);
                      }

                      let attributes = {};
                      try {
                        attributes = JSON.parse(product.attributes || "{}");
                      } catch (error) {
                        console.error("Failed to parse attributes:", error);
                      }

                      return (
                        <tr key={product.product_id}>
                          <td>{(page - 1) * limit + index + 1}</td>
                          <td>
                            <div className="catPara">
                              <img
                                src={imageUrls[0] || categoryIcon}
                                alt={product.product_name || "Product Image"}
                              />
                              {product.product_name || "N/A"}
                            </div>
                          </td>
                          <td>
                            {attributes &&
                            Object.keys(attributes).length > 0 ? (
                              Object.entries(attributes).map(([key, value]) => {
                                const attributeName =
                                  attributesNames[key] || "Unknown Attribute";
                                const values = Array.isArray(value)
                                  ? value
                                  : [value];
                                const maxDisplay = 3;
                                const attributeKey = `${product.product_id}-${key}`;
                                const isExpanded =
                                  !!expandedAttributes[attributeKey];
                                const displayValues = isExpanded
                                  ? values
                                  : values.slice(0, maxDisplay);
                                const remaining = values.length - maxDisplay;

                                return (
                                  <div
                                    key={key}
                                    style={{
                                      marginBottom: "8px",
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap"
                                    }}
                                  >
                                    <strong style={{ marginRight: "8px" }}>
                                      {attributeName}:
                                    </strong>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "4px"
                                      }}
                                    >
                                      {displayValues.map((val, idx) => (
                                        <span
                                          key={idx}
                                          style={{
                                            background: "#f0f0f0",
                                            padding: "2px 6px",
                                            borderRadius: "4px",
                                            fontSize: "0.9em"
                                          }}
                                        >
                                          {val}
                                        </span>
                                      ))}
                                      {remaining > 0 && (
                                        <span
                                          style={{
                                            color: "#007bff",
                                            cursor: "pointer",
                                            alignSelf: "center",
                                            fontSize: "0.9em",
                                            whiteSpace: "nowrap",
                                            marginLeft: "6px"
                                          }}
                                          onClick={() =>
                                            setExpandedAttributes((prev) => ({
                                              ...prev,
                                              [attributeKey]: !isExpanded
                                            }))
                                          }
                                        >
                                          {isExpanded
                                            ? "View Less"
                                            : `+${remaining} more`}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <span>N/A</span>
                            )}
                          </td>

                          <td>
                            {product.product_type === "inventory"
                              ? "Inventory"
                              : product.product_type === "non_inventory"
                              ? "Non Inventory"
                              : "N/A"}
                          </td>
                          <td>{product.sku_code || "N/A"}</td>
                          <td>{product.totalQuantity || "N/A"}</td>
                          <td>${product.retail_price || "N/A"}</td>
                          <td>${product.cost_price || "N/A"}</td>
                          <td>
                            <div
                              className="tblBtnWrp"
                              style={{ width: "200px" }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={product.isAvailable}
                                  onChange={() =>
                                    toggleProductStatus(product.product_id)
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                              <button
                                className="Btn"
                                onClick={() =>
                                  handleEditProduct(product.product_id)
                                }
                              >
                                <img src={pencilIcon} alt="Edit" />
                              </button>
                              <button
                                className="Btn"
                                onClick={() =>
                                  deleteCatagory(product.product_id)
                                }
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </button>
                              <button
                                className="Btn"
                                onClick={() =>
                                  addDates(
                                    product.product_id,
                                    product.fromDate,
                                    product.toDate
                                  )
                                }
                              >
                                <img
                                  style={{ width: "23px", height: "23px" }}
                                  src={calenderIcon}
                                  alt="Delete"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" style={{ textAlign: "center" }}>
                        No Products Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
