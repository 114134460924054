import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import "./SuccessPage.scss";
import combinedServices from "../../../shared/services/user-service";
import CryptoJS from "crypto-js";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import userConstants from "../../../shared/constants/user-constants";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setCartState = useSetRecoilState(cartState);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cartTicketitem, setCartticketitem] = useState([]);
  const [tranctationId, setTranctationId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [payerId, setPayerID] = useState('');
  const [eventdetails, setEventdetails] = useState([]);
  const [transponderAllow, setTransponderallow] = useState(false);

  useEffect(() => {
    const capturePayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      const PayerID = searchParams.get("PayerID");
      setPayerID(PayerID);
  
      const encryptedOrderId = localStorage.getItem("temp_orderId");
      const encryptedEventitemData = localStorage.getItem("EventcartData");
      const secretKey = userConstants.CRYPTOSECRETKEY;
  
      if (!encryptedOrderId) {
        console.error("Order ID is missing.");
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }
  
      const decryptedOrderId = CryptoJS.AES.decrypt(
        encryptedOrderId,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      setOrderId(decryptedOrderId);
  
      let decryptedEventcartitem = [];
      if (encryptedEventitemData) {
        try {
          const decryptedEventcartitems = CryptoJS.AES.decrypt(
            encryptedEventitemData,
            secretKey
          ).toString(CryptoJS.enc.Utf8);
          decryptedEventcartitem = JSON.parse(decryptedEventcartitems);
        } catch (error) {
          console.error("Error decrypting EventcartData:", error);
          setPaymentStatus("failed");
          setIsLoading(false);
          return;
        }
      }
  
      setCartticketitem(decryptedEventcartitem);
  
      if (decryptedEventcartitem.length > 0) {
        let eventClassResponse = await combinedServices.getClassByEvent(
          decryptedEventcartitem[0].event_id
        );
  
        setEventdetails(eventClassResponse);
        setTransponderallow(eventClassResponse.data.event.transponder_allow);
      }
  
      if (!token || !PayerID) {
        console.error("Missing token or PayerID.");
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }
  
      try {
        const response = await combinedServices.capturepayment(
          token,
          PayerID,
          decryptedOrderId
        );
  
        if (response && response.data && response.data.status === 200) {
          console.log("Payment captured successfully:", response.data);
          setPaymentId(response.data.data.id);
          setTranctationId(response.data.data.purchase_units[0].payments.captures[0].id);
          setPaymentStatus("success");
          setCartState([]);
        } else {
          console.error("No payment details found or payment failed.");
          setPaymentStatus("failed");
        }
      } catch (error) {
        console.error("Error capturing payment:", error);
        setPaymentStatus("failed");
      } finally {
        setIsLoading(false);
      }
    };
  
    capturePayment();
  }, [location.search, setCartState]);
  

  // New useEffect to run handlePaymentMethod when eventdetails is updated
  useEffect(() => {
    if (eventdetails.data || paymentStatus === "success") {
      handlePaymentMethod(orderId);
    }
  }, [tranctationId]);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleViewOrders = () => {
    navigate("/MyOrders");
  };

  const handlePaymentMethod = async (order_id) => {
    var newMemberArr = [];
    console.log("aaaaaaaa", eventdetails.data.event.id);
    cartTicketitem.map((memberData) => {
      var data = {
        event_id: eventdetails.data.event.id,
        pit_space: memberData.pit_space
      };
      var classes = [];
      data.user_id = Number(memberData.user_id);
      if (transponderAllow == 1) {
        memberData.selectedClass.map((clsVal) => {
          var x = {
            class_id: clsVal.class_id,
            transponder_id: clsVal.transponder_id,
            transponder_name: clsVal.transponder_name
          };
          classes.push(x);
        });
      } else {
        memberData.selectedClass.map((clsVal) => {
          var x = {
            class_id: clsVal.id
            // transponder_id: clsVal.transponder.id,
            // transponder_name: clsVal.transponder.name,
          };
          classes.push(x);
        });
      }
      data.classIds = classes;
      data.payment_type = " ";

      data.special_note = memberData.special_note;
      newMemberArr.push(data);
    });
    let eventClassResponse = await combinedServices.shopaddEventParticipateMany(
      newMemberArr
    );
    console.log("eeeeeee", eventClassResponse);

    let eventClassResponse1 = await combinedServices.getEventParticipateClass(
      eventClassResponse.data
    );
    var paymentDataArr = [];

    if (eventClassResponse1.data.participantsData) {
      eventClassResponse1.data.participantsData.map(async (value) => {
        var eventParticipantData = value.eventParticipant;
        var data = {
          id: eventParticipantData.id,
          user_id: eventParticipantData.user_id,
          payment_type: "PAYPAL"
        };

        var paymentdata = {
          event_participant_id: eventParticipantData.id,
          payment_for: "event_participation",
          pit_space: false,
          payment_type: "PAYPAL",
          payer_id: payerId,
          payment_id:paymentId
        };
        paymentDataArr.push(paymentdata);

        let paymentTypeResponse = await combinedServices.updatePaymentType(
          data
        );
      });

      let eventMailsResponse = await combinedServices.shopEventaddPaymentMany(
        paymentDataArr
      );

      console.log("responseofpaypa", eventMailsResponse);
      const updatedetails = { ids: eventMailsResponse.data, orderId: order_id };
      let updateorderIds = await combinedServices.updateorderIdinPaymentTable(
        updatedetails
      );
      localStorage.removeItem("EventcartData");
      localStorage.removeItem("temp_orderId");
      localStorage.removeItem("cartItems");
    }
  };

  return (
    <>
      <Header />
      <div className="success-page">
        <div className="success-container">
          {isLoading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Processing your payment. Please do not go back or exit this window.</p>
            </div>
          ) : (
            <>
              <div className="icon-container">
                {paymentStatus === "success" ? (
                  <span className="check-icon success">✔</span>
                ) : (
                  <span className="check-icon failed">✘</span>
                )}
              </div>
              {paymentStatus === "success" ? (
                <>
                  <h1>Payment Successful!</h1>
                  <p>
                    Thank you for your purchase. Your order has been
                    successfully processed.
                  </p>
                  <div className="order-confirmation">
                    <p>We hope to see you again!</p>
                  </div>
                </>
              ) : (
                <>
                  <h1>Payment Failed</h1>
                  <p>
                    Unfortunately, your payment could not be processed. Please
                    try again.
                  </p>
                </>
              )}
              <div className="button-group">
                {paymentStatus === "success" ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={handleViewOrders}
                    >
                      View Orders
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleGoHome}
                    >
                      Go to Home
                    </button>
                  </>
                ) : (
                  <button className="btn btn-secondary" onClick={handleGoHome}>
                    Go to Home
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuccessPage;
