import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import "../CatagoryManagment/AdminNew.scss";
import combinedServices from "../../../shared/services/user-service";
import Event1 from "../../../assets/images/event-img1.jpg";
import Swal from "sweetalert2";

const AddEvents = () => {
  const [eventData, setEventData] = useState([]); // All events
  const [eventActiveData, setEventActiveData] = useState([]); // Active events

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [searchQueryNotAvailable, setSearchQueryNotAvailable] = useState(""); // For Not Available Events
  const [searchQueryAvailable, setSearchQueryAvailable] = useState(""); // For Available Events

  // Fetch all events
  const fetchEvents = async () => {
    try {
      const response = await combinedServices.GetAllEvents(page, limit, "");
      setEventData(response.data.events);
    } catch (error) {
      console.error("Error fetching all events:", error);
    }
  };

  // Fetch active store events
  const fetchActiveStoreEvents = async () => {
    try {
      const response = await combinedServices.GetAllActivestoreEvents(page, limit, "");
      setEventActiveData(response.data.events);
    } catch (error) {
      console.error("Error fetching active store events:", error);
    }
  };

  // Fetch events on component mount
  useEffect(() => {
    fetchEvents();
    fetchActiveStoreEvents();
  }, [page]);

  // Function to update event status
  const eventstatusUpdate = async (id) => {
    try {
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to update the event status?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true
      });

      if (!confirmResult.isConfirmed) return;

      const requestBody = { id };
      const response = await combinedServices.UpdateEventstatusforstore(requestBody);

      if (response) {
        Swal.fire({
          title: "Success!",
          text: `Event status updated successfully.`,
          icon: "success",
          confirmButtonText: "OK"
        });

        // Refresh both lists after the update
        fetchEvents();
        fetchActiveStoreEvents();
      } else {
        throw new Error("Status update failed.");
      }
    } catch (error) {
      console.error("Error updating event status:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to update event status. Please try again.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  // Filtering events based on search queries
  const filteredNotAvailableEvents = eventData.filter((event) =>
    event.full_title.toLowerCase().includes(searchQueryNotAvailable.toLowerCase())
  );

  const filteredAvailableEvents = eventActiveData.filter((event) =>
    event.full_title.toLowerCase().includes(searchQueryAvailable.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Add Events</div>
          <div className="whtBoxWrpOuter">
            <form>
            <div className="whtBoxWrpper" style={{ display: "flex", gap: "20px" }}>
            <div className="wbx_Cont wbx_left" style={{ flex: 1, maxHeight: "650px", overflowY: "auto" }}>
                  <h1 style={{ textAlign: "center" }}>Not Available in store Events</h1>
                  <br />
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQueryNotAvailable}
                      onChange={(e) => setSearchQueryNotAvailable(e.target.value)}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search Not Available Events"
                    />
                  </div>
                  <br />
                  <ul className="d-flex flex-wrap p-0 productList">
                    {filteredNotAvailableEvents.map((event, index) => (
                      <li
                        className="col-12 col-md-3 mb-3"
                        key={index}
                        onClick={() => eventstatusUpdate(event.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner">
                          <div className="img">
                            <img
                              src={event.image?.startsWith("https://nambaweb.s3") ? event.image : Event1}
                              alt={event.full_title || `Event ${index + 1}`}
                            />
                          </div>
                          <div className="name mt-3 text-center">
                            <div className="event-title">{event.full_title || "Event Title"}</div>
                            <span className="d-block pt-1 event-price" style={{ fontSize: "12px", color: "#666", fontWeight: "bold" }}>
                              ${parseFloat(event.registration_charge || "0.00").toFixed(2)} USD
                            </span>
                            <span className="d-block event-date" style={{ fontSize: "12px", color: "#999" }}>
                              {new Date(event.registration_open).toLocaleDateString()} - {new Date(event.registration_close).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="wbx_Cont wbx_right" style={{ flex: 1, maxHeight: "650px", overflowY: "auto" }}>
                  <h1 style={{ textAlign: "center" }}>Available in store Events</h1>
                  <br />
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQueryAvailable}
                      onChange={(e) => setSearchQueryAvailable(e.target.value)}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search Available Events"
                    />
                  </div>
                  <br />
                  <ul className="d-flex flex-wrap p-0 productList">
                    {filteredAvailableEvents.map((event, index) => (
                      <li
                        className="col-12 col-md-3 mb-3"
                        key={index}
                        onClick={() => eventstatusUpdate(event.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner">
                          <div className="img">
                            <img
                              src={event.image?.startsWith("https://nambaweb.s3") ? event.image : Event1}
                              alt={event.full_title || `Event ${index + 1}`}
                            />
                          </div>
                          <div className="name mt-3 text-center">
                            <div className="event-title">{event.full_title || "Event Title"}</div>
                            <span className="d-block pt-1 event-price" style={{ fontSize: "12px", color: "#666", fontWeight: "bold" }}>
                              ${parseFloat(event.registration_charge || "0.00").toFixed(2)} USD
                            </span>
                            <span className="d-block event-date" style={{ fontSize: "12px", color: "#999" }}>
                              {new Date(event.registration_open).toLocaleDateString()} - {new Date(event.registration_close).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEvents;
