import React, { useState, useEffect } from "react";
import combinedServices from "../../../shared/services/user-service";
import { useNavigate } from "react-router-dom";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import "./ProductListing.scss";
import Event1 from "../../../assets/images/event-img1.jpg";

const ProductListing = () => {
  const [productsData, setProductsData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let productsResponse = await combinedServices.getProductsforUser();
        if (productsResponse?.data?.data) {
          setProductsData(productsResponse.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };
    const fetchStoreStatus = async () => {
      try {
        const response = await combinedServices.storeStatus();
        const storeData = response?.data?.data?.data?.[0];
        if (storeData && !storeData.isAvailable) {
          navigate("/Store");
        }
      } catch (error) {
        console.error("Error fetching Store status:", error);
        navigate("/");
      }
    };
    fetchProducts();
    fetchStoreStatus();
  }, [navigate]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        let eventsResponse = await combinedServices.getEventsforusers();
        if (eventsResponse?.data?.events) {
          setEventData(eventsResponse.data.events || []);
        }
      } catch (error) {
        console.error("Error fetching Events:", error);
      }
    };
    fetchEvents();
  }, []);

  const handleProductClick = (id) => {
    navigate(`/productDetail/${id}`);
  };

  const handleEnterEvent = (id) => {
    navigate(`/eventTicket/${id}`);
  };

  const myOrders = () => {
    navigate(`/MyOrders`);
  };

  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="mainCont">
          <section className="ContactOuter pt-5 pb-5">
            <div className="container">
              <div className="d-block d-sm-flex flex-wrap justify-content-between align-items-end pb-5">
                <div>
                  <div className="mb-3 productHd">
                    <span style={{ fontSize: "35px" }}>BOAT RACE</span> 1,000+
                    RELEVANT RESULTS
                  </div>
                  <div className="d-flex">
                    <select className="formSelect small me-3">
                      <option>Price ($)</option>
                    </select>
                    <select className="formSelect small">
                      <option>All Sellers</option>
                    </select>
                  </div>
                </div>

                <div className="mt-3 mt-sm-0">
                  <div className="filBtnWrp">
                    <button className="nambaBtn" onClick={myOrders}>
                      My Orders
                    </button>
                  </div>
                  <select className="formSelect small">
                    <option>Sort by: Relevance</option>
                  </select>
                </div>
              </div>

              <div className="mb-5">
                <div className="productHd mb-3">
                  <span style={{ fontSize: "30px" }}>TICKET AND EVENTS</span>
                </div>
                <ul className="d-flex flex-wrap p-0 productList">
                  {eventData
                    .slice(0, showAllEvents ? eventData.length : 8)
                    .map((event, index) => (
                      <li
                        className="col-6 col-md-3"
                        key={event.id || index}
                        onClick={() => handleEnterEvent(event.id)}
                      >
                        <div className="inner">
                          <div className="img">
                            <img
                              src={
                                event.image?.startsWith("https://nambaweb.s3")
                                  ? event.image
                                  : Event1
                              }
                              alt={event.full_title || `Event ${index + 1}`}
                            />
                          </div>
                          <div className="name mt-3">
                            {event.full_title || "Event Title"}
                            <span className="d-block">
                              {new Date(event.event_start).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric"
                                }
                              )}{" "}
                              -{" "}
                              {new Date(event.event_end).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric"
                                }
                              )}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {eventData.length > 8 && (
                  <div className="text-center mt-4">
                    <button
                      className="nambaBtn"
                      style={{marginLeft:'0px'}}
                      onClick={() => setShowAllEvents(!showAllEvents)}
                    >
                      {showAllEvents
                        ? "Show Less Tickets"
                        : "Show More Tickets"}
                    </button>
                  </div>
                )}
              </div>

              <div>
                <div className="productHd mb-3">
                  <span style={{ fontSize: "30px" }}>PRODUCTS</span>
                </div>
                <ul className="d-flex flex-wrap productList p-0">
                  {productsData
                    .slice(0, showAllProducts ? productsData.length : 8)
                    .map((product) => {
                      const images = JSON.parse(product.images || "[]");
                      const isOutOfStock = product.totalQuantity === "0";

                      return (
                        <li
                          className="col-6 col-md-3"
                          key={product.product_id}
                          onClick={() =>
                            !isOutOfStock &&
                            handleProductClick(product.product_id)
                          }
                          style={{
                            cursor: isOutOfStock ? "not-allowed" : "pointer",
                            opacity: isOutOfStock ? 0.4 : 1
                          }}
                        >
                          <div className="inner">
                            <div className="img">
                              <img
                                src={
                                  images[0] || "https://via.placeholder.com/150"
                                }
                                alt={product.product_name || "Product"}
                              />
                            </div>
                            <div
                              className="name mt-3"
                              style={{
                                color: isOutOfStock ? "red" : "inherit"
                              }}
                            >
                              {product.product_name || "Product Name"}
                              {isOutOfStock && <span> (Out of Stock)</span>}
                              <span className="d-block pt-1">
                                $
                                {parseFloat(product.retail_price || 0).toFixed(
                                  2
                                )}{" "}
                                USD
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
                {productsData.length > 8 && (
                  <div className="text-center mt-4">
                    <button
                      className="nambaBtn"
                      style={{marginLeft:'0px'}}
                      onClick={() => setShowAllProducts(!showAllProducts)}
                    >
                      {showAllProducts
                        ? "Show Less Products"
                        : "Show More Products"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductListing;
