import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import pencilIcon from "../../../assets/images/pencil.svg";
import deleteIcon from "../../../assets/images/bin.svg";
import Swal from "sweetalert2";
import combinedServices from "../../../shared/services/user-service";

const InventoryList = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [totalInventory, setTotalInventory] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [attributesNames, setAttributesNames] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const totalPages = Math.ceil(totalInventory / limit);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        let inventoryData = await combinedServices.getInventory(
          page,
          limit,
          searchQuery
        );
        if (inventoryData && inventoryData.data) {
          setInventoryData(inventoryData.data.data || []);
          setTotalInventory(inventoryData.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };
    fetchInventory();
  }, [page, limit, searchQuery, deleteTrigger, isModalOpen]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await combinedServices.getallAttribute();
        console.log("Attribute Response:", response);

        if (response && response.data) {
          const names = response.data.existingCatagory
            .map((attribute) => {
              return {
                [attribute.attribute_id]: attribute.attributes
              };
            })
            .reduce((acc, curr) => Object.assign(acc, curr), {});

          setAttributesNames(names);
          console.log(names);
        } else {
          console.error("Unexpected response format for attributes:", response);
        }
      } catch (error) {
        console.error("Error fetching attribute names:", error);
      }
    };
    fetchAttributes();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const toggleInventoryStatus = async (id) => {
    try {
      // Optimistic UI Update
      setInventoryData((prevInventoryData) =>
        prevInventoryData.map((item) =>
          item.inv_id === id
            ? { ...item, isAvailable: !item.isAvailable }
            : item
        )
      );

      const response = await combinedServices.changeInvStatus({
        inventory_id: id
      });

      if (response.status !== 200) {
        // Revert if API fails
        setInventoryData((prevInventoryData) =>
          prevInventoryData.map((item) =>
            item.inv_id === id
              ? { ...item, isAvailable: !item.isAvailable }
              : item
          )
        );
        Swal.fire(
          "Warning!",
          "Failed to update status on the server.",
          "warning"
        );
      }
    } catch (error) {
      // Revert on error
      setInventoryData((prevInventoryData) =>
        prevInventoryData.map((item) =>
          item.inv_id === id
            ? { ...item, isAvailable: !item.isAvailable }
            : item
        )
      );
      console.error("Error changing status:", error);
      Swal.fire("Error!", "Failed to change status of inventory.", "error");
    }
  };

  const handleUpdateQuantity = async (inventory_id, quantity, price) => {
    try {
      if (!inventory_id) {
        throw new Error("inventory ID must be provided.");
      }

      const inventory = { inventory_id, quantity, price };
      const InventoryData = await combinedServices.updateQuantity(inventory);

      if (InventoryData?.status === 200) {
        setIsModalOpen(false);
        Swal.fire(
          "Updated!",
          InventoryData.data.message || "The Inventory has been Updated.",
          "success"
        );
      } else {
        Swal.fire("Warning!", "Error in updation", "warning");
      }
    } catch (error) {
      Swal.fire("Warning!", "Error in updation", error, "warning");
    }
  };
  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Stock Inventory List</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product, Category and SKU Code"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Product Name</th>
                    <th>Category Name</th>
                    <th>Attributes</th>
                    <th>SKU Code</th>
                    <th>Cost Price</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryData.length > 0 ? (
                    inventoryData.map((product, index) => {
                      // Parse images and attributes
                      let attributes = {};
                      try {
                        attributes = JSON.parse(product.attribute || "{}");
                      } catch (error) {
                        console.error("Failed to parse attributes:", error);
                      }

                      return (
                        <tr key={product.inv_id}>
                          {/* Serial Number */}
                          <td>{(page - 1) * limit + index + 1}</td>

                          {/* Product Name */}
                          <td>
                            <div className="catPara">
                              <img
                                src={
                                  product.product?.images
                                    ? JSON.parse(
                                        product.product.images || "[]"
                                      )[0]
                                    : categoryIcon
                                }
                                alt={
                                  product.product?.product_name ||
                                  "Product Image"
                                }
                              />
                              {product.product?.product_name || "N/A"}
                            </div>
                          </td>

                          {/* Category Name */}
                          <td>{product.category?.category || "N/A"}</td>

                          {/* Attributes */}
                          <td>
                            {attributes &&
                            Object.keys(attributes).length > 0 ? (
                              Object.entries(attributes).map(([key, value]) => {
                                const attributeName =
                                  attributesNames[key] || "Unknown Attribute";
                                return (
                                  <div key={key}>
                                    <strong>{attributeName}:</strong>{" "}
                                    {Array.isArray(value)
                                      ? value.join(", ")
                                      : value}
                                  </div>
                                );
                              })
                            ) : (
                              <span>N/A</span>
                            )}
                          </td>

                          <td>{product.product?.sku_code || "N/A"}</td>
                          <td>${product.price || "N/A"}</td>
                          <td>{product.quantity}</td>
                          <td>
                            <div
                              className={`status-badge ${
                                product.quantity === 0
                                  ? "out-of-stock"
                                  : product.isAvailable
                                  ? "available"
                                  : "not-available"
                              }`}
                            >
                              {product.quantity === 0
                                ? "Out of Stock"
                                : product.isAvailable
                                ? "Available"
                                : "Not Available"}
                            </div>
                          </td>

                          {/* <td>{product.product?.fromDate}</td>
                          <td>{product.product?.toDate}</td> */}
                          {/* Updated On */}
                          {/* <td>
                            {product.updatedOn
                              ? new Date(product.updatedOn).toLocaleDateString()
                              : "N/A"}
                          </td> */}

                          {/* Available Status */}
                          <td>
                            <div
                              className="tblBtnWrp"
                              style={{ width: "120px" }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={product.isAvailable}
                                  onChange={() =>
                                    toggleInventoryStatus(product.inv_id)
                                  }
                                  disabled={product.loading}
                                />
                                <span className="slider"></span>
                              </label>
                              <button
                                className="Btn"
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setIsModalOpen(true);
                                }}
                              >
                                <img src={pencilIcon} alt="pencilIcon" />
                              </button>
                              {/* <button
                                className="Btn"
                                //   onClick={() => deleteCatagory(category.category_id)}
                              >
                                <img src={deleteIcon} alt="deleteIcon" />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" style={{ textAlign: "center" }}>
                        No Products Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Modal */}
        <QuantityModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          product={selectedProduct}
          updateQuantity={handleUpdateQuantity}
        />
      </div>
    </>
  );
};

export default InventoryList;

// Modal for edit
const QuantityModal = ({ isOpen, onClose, product, updateQuantity }) => {
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (product) {
      setQuantity(product.quantity?.toString() || "");
      setPrice(product.price?.toString() || "");
    }
  }, [product]);

  if (!isOpen) return null;

  const handleSave = () => {
    if (product && product.inv_id && /^\d+$/.test(quantity)) {
      // Use parseFloat instead of parseInt for price to preserve decimals
      updateQuantity(
        product.inv_id, 
        parseInt(quantity, 10), 
        parseFloat(price) // Changed from parseInt to parseFloat
      );
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;

    // Allow:
    // 1. Empty string (for clearing the field)
    // 2. Numbers without decimal (e.g., "123")
    // 3. Numbers with decimal and up to 2 digits after (e.g., "123.45")
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      setPrice(value);
    }
  };

  const handleBlur = () => {
    if (quantity === "") {
      setQuantity("0");
    }
  };

  const handleBlurPrice = () => {
    if (price === "") {
      setPrice("0");
    }
  };

  return (
    <div className="catagorylistmodal">
      <div className="catagorylistmodal-content">
        <h2>Update Inventory</h2>
        <br />
        <form className="category-form">
          <label style={{ fontWeight: "800" }} htmlFor="quantity">
            Current Quantity
          </label>
          <input
            id="quantity"
            type="text"
            value={quantity}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Quantity"
          />
          <label style={{ fontWeight: "800" }} htmlFor="price">
            Current Price
          </label>
          <input
            id="price"
            type="number"
            value={price}
            onChange={handlePriceChange}
            onBlur={(e) => {
              // Format the value on blur to ensure 2 decimal places
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                setPrice(value.toFixed(2));
              }
            }}
            placeholder="Enter Price"
            min="0"
            step="0.01"
            onKeyDown={(e) => {
              // Prevent negative numbers and scientific notation
              if (["-", "e", "E", "+"].includes(e.key)) {
                e.preventDefault();
              }
              // Allow only one decimal point
              if (e.key === "." && e.target.value.includes(".")) {
                e.preventDefault();
              }
            }}
          />
          <div className="catagorylistmodal-actions">
            <div className="cancel-icon" onClick={onClose}>
              &#10006;
            </div>
            <button
              type="button"
              className="save-btn"
              onClick={handleSave}
              disabled={quantity === ""}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div className="catagorylistmodal-overlay" onClick={onClose}></div>
    </div>
  );
};
